import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'

import { toast } from 'react-toastify';
import {BASE_URL} from './baseUrl'


export default function useOrderWaybill_1() {

 const {myKey} = useContext(AuthContext)
 const [formData, setFormData] = useState({
        order_ids : "",
      
});


 
 const postDataWaybill_1 = (ids)=>{

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Token ${myKey}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "csrftoken=jNkwPDYQZvojUCx1OJagxFM1IUozQmESTFyy2KPlEj0cstHBuiiCnBZgZgnUiLeE");

    let request_url = BASE_URL+"api/v1/order/shipment_waybill_pdf/"
    

    var raw = JSON.stringify({
      "order_ids" : ids,
     
     });
    
 

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(request_url, requestOptions)
    .then(response => {
      if (response.ok) {
        return response.blob(); 
      } else {
        throw new Error("PDF alınamadı");
      }
    })
    .then(blob => {
      const pdfUrl = URL.createObjectURL(blob);
      window.open(pdfUrl, '_blank'); 
    })
    .catch(error => {
      console.log('error', error);
      toast.error("PDF alınırken bir hata meydana geldi.", { theme: 'colored'});
    });

  }

 return {setFormData, formData, postDataWaybill_1}
}
