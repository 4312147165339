import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Card, Col, Row, Table, Form} from 'react-bootstrap';
// import { orderedProductsData } from 'data/ecommerce/OrderDetailsData';
import { getPercentage } from 'helpers/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import useForecastData from 'hooks/useForecastData';
import IconButton from 'components/common/IconButton';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useForecastExport from 'hooks/useForecastExport';



const ForecastDetailsPage = () => {


  const navigate = useNavigate();
  const {mySelectData, setStoreTablo, myDataTable, setBarcodeTablo} = useForecastData()
  const {getDataExport} = useForecastExport()


  const handleChange = (e)=> {
    setStoreTablo(e.target.value); 
  }

  const handleSkuChange = (e)=> {
    setBarcodeTablo(e.target.value); 
  }

  // modal

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);


const handleExport = ()=> {
  getDataExport(); 

}


const orderedProductsData = myDataTable?.map((product, i) =>{
  const date = new Date(product.DateTime);
  const formattedDate = date.toLocaleDateString("tr-TR", {day: '2-digit', month: '2-digit', year: 'numeric'});

  const startDate = new Date(product.DateTime);
  const resultArray = JSON.parse(product.Result);
  const resultLength = (resultArray || 1).length;



  return {
  id : i+1,
  name: product.Product__Title,
  date: formattedDate,
  barcode : product.Product__barcodes__Barcode,
  image: product.Product__Image,
  sku : product.Product__SKU,
  result : product.Result,
  store : product.VendorStore__Store__Name,
  resultOne: resultArray?.map((value, j) => ({value: value})),
  dateOne: resultArray?.map((_, j) => {
    const date = new Date(startDate);
    date.setDate(date.getDate() + j);
    return { date: date.toLocaleDateString("tr-TR", { day: '2-digit', month: '2-digit' }) };
  })
    
}});


const barcodeSet = new Set();

  return (
    <>
    <Card className="mb-3">
      <Card.Body>
        <div className="table-responsive fs--1">
        <div style={{display:'flex', justifyContent:'space-between',marginBottom:"15px"}}>
          
        <h6 >Satış Tahminleri</h6>
        <div style={{display:'flex'}}>
       
        <div style={{marginRight:"10px"}}>
          <Form.Select  className='form-control'  onChange={handleChange}>
          
                     <option value="">Hepsi</option>
                     {Array.from(new Set(mySelectData?.map((item) => item.VendorStore__Store__Name))).map((storeName, i) => (
                <option key={i} value={storeName}>{storeName}</option>
              ))}
                </Form.Select> 
                </div>

                <div style={{marginRight:"10px"}}>
                <Form.Select  className='form-control'  onChange={handleSkuChange}>

                <option value="">SKU</option>

                {mySelectData?.map((item, i) => {
                if (!barcodeSet.has(item.Product__barcodes__Barcode)) {
                    barcodeSet.add(item.Product__barcodes__Barcode);
                    return <option key={i} value={item.Product__barcodes__Barcode}>{item.Product__SKU}</option>;
                }
              })}
                </Form.Select> 
                </div>
                <div style={{marginLeft:"8px", marginRight:"8px"}}>
                <IconButton
                        variant="falcon-default"
                        size="m"
                        icon="external-link-alt"
                        transform="shrink-3"
                        onClick={handleShow}
                      >
                        <span className="d-none d-sm-inline-block ms-1">Export</span>
                      </IconButton>
                      </div>

          <div id="orders-actions">
           <IconButton
              variant="falcon-default"
              size="m"
              icon="plus"
              transform="shrink-3"
              onClick = {()=>navigate("/dashboard/analytics/campaing")}
            >
              <span className="ms-1">Kampanya Oluştur</span>
            </IconButton>
            </div>

        
        </div>

        </div>
          <Table striped className="border-bottom">
          
            <thead className="bg-200 text-900">
              <tr>
                <th className="border-0">Ürün</th>
                <th className="border-0">İsim</th>
                <th className="border-0">Mağaza</th>
                <th className="border-0 text-left">SKU</th>
               
                <th className="border-0 ml-3 text-center">Tahminler</th>
              </tr>
            </thead>
            <tbody>
              {orderedProductsData?.length>0 && orderedProductsData?.map(product => (
                <tr key={product.id} className="border-200">
                  <td className="align-middle">
                    <img style={{width:"40px"}}src={product.image} alt="" />
                 
                  </td>
                  <td className="align-middle text-left" style={{whiteSpace: 'normal', width:"750px"}}
                  title={product.name}
                  onMouseOver={(e) => (e.currentTarget.title = product.name)}
                  >
                
                  {product.name.length > 15
                      ? product.name.slice(0, 15).concat("...")
                      : product.name}
                  </td>
                  <td className="align-middle">
                  
                    <h6 className="mb-0 text-nowrap">{product.store}</h6>
                  </td>
                
                  <td className="align-middle text-left">
                    
                    {product.sku}
                  </td>
                 


                  <td className="align-middle text-end" >
                     
                  <td className="align-middle text-end">
                        <table style={{ borderCollapse: "collapse" }}>
                          <tr>
                          <span style={{fontWeight:"bold", marginTop:"5px", marginRight:"10px"}}>Tarih:</span>{product.dateOne?.map((item, index) => (
                              <td
                                style={{
                                  textAlign: "center",
                                  borderBottom: "1px solid black",
                                  padding: "5px",
                                  borderRight:
                                    index !== product.dateOne.length - 1 ? "1px solid black" : "none",
                                    fontWeight:"600"
                                }}
                                key={index}
                              >
                                {item.date}
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <span style={{fontWeight:"bold", marginTop:"5px", marginRight:"10px"}}>Adet:</span> {product.resultOne?.map((item, index) => (
                              <td
                                style={{
                                  textAlign: "center",
                                  padding: "5px",
                                  borderRight:
                                    index !== product.dateOne.length - 1 ? "1px solid black" : "none",
                                }}
                                key={index}
                              >
                                {item.value}
                              </td>
                            ))}
                          </tr>
                        </table>
                      </td>

                  
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Row className="g-0 justify-content-end">
            <Col xs="auto">
             
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
    
    <Modal 
      show={show} 
      onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Tahmin Listesi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          Tahmin Listesini İndir!

          </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Vazgeç
          </Button>
          <Button variant="primary" onClick={handleExport}>
            İndir
          </Button>
        </Modal.Footer>
      </Modal> 
   
    </>
  );
};

ForecastDetailsPage.propTypes = {};

export default ForecastDetailsPage;
