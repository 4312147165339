export const dashboardRoutes = {
  label: 'Dashboard',
  labelDisable: true,
  
  children: [
    {
      name2: 'ANALİZ PANELİ',
      active: true,
      icon: 'chart-pie',

      
      children: [
        {
          name: 'Satış Analizleri',
          to: '/dashboard',
          exact: true,
          active: true,
          

          // icon: 'chart-line',
        },
        {
          name: 'Raporlar',
          to: '/dashboard/report',
          exact: true,
          active: true,
          

          // icon: 'chart-line',
        },
        {
          name: 'Tahminler',
          to: '/dashboard/analytics',
          active: true,
          exact: true,
          permissions: ['Dashboard Askıya Alınan Geliştirmeleri Görme'],

        },
        {
          name: 'Kampanyalar',
          to: '/dashboard/analytics/campaing/list',
          active: true,
          exact: true,
          permissions: ['Dashboard Askıya Alınan Geliştirmeleri Görme'],

        },
        
      ]
    }
  ]
};
export const appRoutes = {
  label: 'app',
  labelDisable: true,
  children: [
{
      name2: 'ÜRÜN YÖNETİMİ',
      active: true,
      icon: 'tags',
      children: [
        {
          // icon: 'shapes',
          name: 'Ürün Listesi',
          to: '/e-commerce/product/product-all',
          active: true,
          exact: true,
        },
        {
          // icon: 'shapes',
          name: 'Ürün Eşleştirme',
          to: '/e-commerce/product/vendor-store-product',
          active: true,
          exact: true,
          
        },
        {
          name: 'Envanterim',
          to: '/e-commerce/product/product-inventory',
          active: true,
          exact: true,
          // icon: 'layer-group',
        }
       ]
 },

 {
        name2: 'SİPARİŞ YÖNETİMİ',
        active: true,
        icon: 'shopping-cart',
        children: [
          {
            name: 'Sipariş Listesi',
            to: '/e-commerce/orders/order-list',
            active: true,
            exact: true,
            // icon: 'table',
          },
          {
            name: 'B2B Sipariş Listesi',
            to: '/e-commerce/orders/order-b2b-list',
            active: true,
            exact: true,
            permissions: ['Sipariş B2B Ekleme Yetkisi'],
            badge: {
              type: 'success',
              text: 'Yeni'
            },
          //   // icon: 'table',
          },
          // {
          //   name: 'İade Sipariş Listesi',
          //   to: '/e-commerce/orders/order-list-return',
          //   active: true,
          //   exact: true,
          // },
          {
            name: 'İade Siparişler',
            to: '/e-commerce/orders/order-list-refund',
            active: true,
            exact: true,
            // icon: 'question-circle',
            // badge: {
            //   type: 'success',
            //   text: 'Yeni'
            // }
          },
          {
            name: 'Kurye Teslimatları',
            to: '/e-commerce/orders/order-delivery',
            active: true,
            exact: true,
            permissions: ['Teslimat Sayfası Erişimi'],

            // icon: 'question-circle',
            // badge: {
            //   type: 'success',
            //   text: 'Yeni'
            // }
          },
          {
            name: 'Sipariş Maliyetleri',
            to: '/e-commerce/orders/order-list-costs',
            active: true,
            exact: true,
            permissions: ['Dashboard Askıya Alınan Geliştirmeleri Görme'],
          },
            {
            name: 'Sipariş Faturaları',
            to: '/e-commerce/orders/order-list-invoices',
            active: true,
            exact: true,
            permissions: ['Sipariş Faturalarına Erişim'],
          },
          {
            name: 'İş Emri Talebi Oluştur',
            to: '/e-commerce/orders/order-work',
            active: true,
            exact: true,
            // icon: 'question-circle',
            permissions: ['Sipariş İş Emri Dashboard'],
            badge: {
              type: 'success',
              text: 'Yeni'
            }
          },
          {
            name: 'İş Emri Talep Listesi',
            to: '/e-commerce/orders/order-work-list',
            active: true,
            exact: true,
            permissions: ['Sipariş İş Emri Dashboard'],
            // icon: 'table',
            badge: {
              type: 'success',
              text: 'Yeni'
            }
          },
          {
            name: 'İş Emri Oluştur',
            to: '/e-commerce/wms/order-work',
            active: true,
            exact: true,
            permissions: ['Dashboard İş Emri Sayfası Görebilme'],
            // icon: 'table',
            badge: {
              type: 'success',
              text: 'Yeni'
            }
          },
          {
            name: 'Kargo Barkodu Yazdır',
            to: '/e-commerce/orders/order-print',
            active: true,
            exact: true,
            permissions: ['Sipariş Ortak İrsaliye Basma'],
            // icon: 'table',
            badge: {
              type: 'success',
              text: 'Yeni'
            }
          },
          {
            name: 'Kargo Firması Atama',
            to: '/e-commerce/wms/order-cargo',
            active: true,
            exact: true,
            permissions: ['Dashboard Kargo Aksiyonları Sayfasını Görebilme'],
            // icon: 'table',
            badge: {
              type: 'success',
              text: 'Yeni'
            }
          },
          {
            name: 'Paketleme Sayfası',
            to: '/e-commerce/orders/order-packaging',
            active: true,
            exact: true,
            permissions: ['Paketleme Sayfası'],
            // icon: 'table',
            badge: {
              type: 'success',
              text: 'Yeni'
            }
          }
        ]
  },
      {
          name2: 'DEPO YÖNETİMİ',
          active: true,
          icon: 'shapes',
          children: [
            {
              name: 'Depo Durumu',
              to: '/e-commerce/inventory-product',
              active: true,
              exact: true,
              // icon: 'ticket-alt',
             
            },
            {
              name: 'Depo Ürünleri',
              to: '/e-commerce/inventory-product-all',
              active: true,
              exact: true,
              // icon: 'ticket-alt',
              badge: {
                type: 'success',
                text: 'Yeni'
              }
             
            },
           
            {
              name: 'Ürün Aktiviteleri',
              to: '/e-commerce/inventory-product-activity',
              active: true,
              exact: true,
              // icon: 'ticket-alt',
              badge: {
                type: 'success',
                text: 'Yeni'
              }
             
            },
             {
                name: 'Depoya Teslimatlar',
                to: '/e-commerce/customer-details',
                active: true,
                exact: true,
                // icon: 'ticket-alt',
               
              },
              {
                name: 'Aksiyon Geçmişi',
                to: '/user/actions-history',
                active: true,
                exact: true,
                permissions : ['Dashboard Aksiyon Geçmişi Sayfası Görebilme'],
                // icon: 'ticket-alt',
                badge: {
                  type: 'success',
                  text: 'Yeni'
                }
               
              },
               {
                name: 'Depo Sayımları',
                to: '/inventory-product/inventory-product-count',
                active: true,
                exact: true,
                badge: {
                  type: 'success',
                  text: 'Yeni'
                }
              },
              // {
              //   name: 'Depolar',
              //   to: 'e-commerce/inventory-map',
              //   active: true,
              //   exact: true,
              // }
          ]
        },
        {
          name2: 'MALİYET YÖNETİMİ',
          active: true,
          icon: 'chart-line',
          children: [ 
            {
              name: 'Maliyet Hesaplaması',
              to: '/costs-calculation',
              active: true,
              exact: true,
              // icon: 'ticket-alt',
             
            },
            {
              name: 'Sipariş Maliyetleri',
              to: '/costs-order',
              active: true,
              exact: true,
              // icon: 'ticket-alt',
             
            },
            {
              name: 'Depolama Maliyetleri',
              to: '/costs-region',
              active: true,
              exact: true,
              // icon: 'ticket-alt',
              
             
            },
           
            {
              name: 'Mal Kabul Maliyetleri',
              to: '/costs-inbound',
              active: true,
              exact: true,
              // icon: 'ticket-alt',
             
             
            },
             {
                name: 'İade Maliyetleri',
                to: '/costs-refund',
                active: true,
                exact: true,
                // icon: 'ticket-alt',
               
              },
           
          
          ]
        },

        // {
        //   name2: 'KOLAY İADE',
        //   active: true,
        //   icon: 'layer-group',
        //   children: [
        //     {
        //       name: 'İade Siparişler',
        //       to: '/refund-list',
        //       active: true,
        //       exact: true,
        //       // icon: 'ticket-alt',
             
        //     },
        //     {
        //       name: 'İade Ürünler',
        //       to: '/refund-product',
        //       active: true,
        //       exact: true,
        //       // icon: 'ticket-alt',
             
        //     },
        //      {
        //         name: 'Eksik ürün talepleri',
        //         to: '/missing-list',
        //         active: true,
        //         exact: true,
        //         // icon: 'ticket-alt',
               
        //       },
        //       {
        //         name: 'Paket Ulaşmama Talebi',
        //         to: '/package-list',
        //         active: true,
        //         exact: true,
        //         // icon: 'ticket-alt',
               
        //       },
        //       {
        //         name: 'Fatura Talepler',
        //         to: '/invoice-list',
        //         active: true,
        //         exact: true,
        //       }
        //   ]
        // },

        {
                name2: 'ENTEGRASYONLAR',
                active: true,
                icon: 'wrench',
                children: [
                  {
                      name: 'Entegrasyon Oluştur',
                      to: '/e-commerce/integration',
                      active: true,
                      exact: true,
                      // icon: 'puzzle-piece',
                  },
                  {
                    name: 'Entegrasyonlarım',
                    to: '/e-commerce/integration-available',
                    permissions: ['Dashboard Entegrasyon Düzenleme'],
                    active: true,
                    exact: true,
                    // icon: 'puzzle-piece',
                }

                
                ]
        },

        
  ]
};




export const pagesRoutes = {
  label: '',
  labelDisable: true,
  children: [

  ]
};

export const modulesRoutes = {
  label: '',
  labelDisable: true,
  children: [
 
  ]
};

export const documentationRoutes = {
  label: '',
  labelDisable: true,
  children: [

  ]
};

export default [
  dashboardRoutes,
  appRoutes,
  pagesRoutes,
  modulesRoutes,
  documentationRoutes
];
