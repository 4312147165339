import React, { useContext, useEffect, useRef, useState } from 'react';
import { Card, Dropdown, Form, Col, InputGroup, Alert, Row, Popover, Overlay, Tooltip, OverlayTrigger, ButtonGroup} from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import OrdersTableHeader from './OrdersTableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import useOrderList from 'hooks/useOrderList';
import Pagination from './PaginationOrder';
import { getDatasetAtEvent } from 'react-chartjs-2';
import DatePicker from  "react-datepicker";
import useShipmentCompany from 'hooks/useShipmentCompany';
import Accordion from 'react-bootstrap/Accordion';
import AsyncSelect from 'react-select/async';
import IconButton from 'components/common/IconButton';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useOrderExport from 'hooks/useOrderExport';
import useVendorStore from 'hooks/useVendorStore';
import { EmojiAngryFill, Search } from 'react-bootstrap-icons';
import gif from 'assets/img/gif_export.gif'
import gifOrder from 'assets/img/gif_order.gif'
import useOrderExportS3 from 'hooks/useOrderExportS3';
import useInventoryListFilter from 'hooks/useInventoryListFilter';
import {  PermsContext } from 'context/PermsContext';
import useVendorList from 'hooks/useVendorList';
import gif_s3 from 'assets/img/gif_export_s3.gif'
import { useOrderPdfUpload } from 'hooks/useOrderPdfUpload';
import { useOrderUpload } from 'hooks/useOrderUpload';
import useOrderCancel from 'hooks/useOrderCancel';
import useOrderWorkPost from 'hooks/useOrderWorkPost';
import './style.css'
import useUserChargeBy from 'hooks/useUserChargeBy';
import { BsThreeDots, BsPencilSquare } from "react-icons/bs";
import { RiDeleteBin2Line, RiTruckLine, RiShoppingCart2Line, RiFileUploadLine } from "react-icons/ri";
import useOrderCargoShip from 'hooks/useOrderCargoShip';
import useOrderAddressUpdate from 'hooks/useOrderAddressUpdate';


const Orders = () => {

const {getOrderWork}= useOrderWorkPost()
const {postOrderCargoShip}= useOrderCargoShip()
const {updateAddress, setFormData, formData, setOrderId} = useOrderAddressUpdate()

const {perms} = useContext(PermsContext)

const {myData, setPage, selectStatus, setSelectStatus, startDate, setStartDate, endDate, setEndDate, setCargo, cargo, getData, firstName, setFirstName, setStore, setSearch, setPaid, showOrderGif, currentPage,setCurrentPage, followersPerPage, setFollowersPerPage, totalPages, setInvoices, setInventoryRegion , sortAmount, setSortAmount, setVendorIdList, setWork, setIsLoading, isLoading,  getDetailWork, detailWork, setSearchName, setUserId, setWmsStatus, shipmentStartDate, shipmentEndDate, setShipmentStartDate, setShipmentEndDate, setPackedEndDate, setPackedStartDate, packedStartDate, packedEndDate, setInvoiceCountry, setFulfilled} = useOrderList()


  const columns = [
    {
      accessor: 'name',
      Header: 'Sipariş',
      disableSortBy: true,
      headerProps: { className: 'text-left'},
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { id, name, email, note } = rowData.row.original;
        const state = { id, activePage };
        const renderTooltip = (props) => (
          <Tooltip id={`orderNote-${id}`} {...props}
          bsPrefix='my-tooltip-class'
          >
            {note}
          </Tooltip>
        );

        return (
          <>
            {note ? (
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <span>
                 
                  <FontAwesomeIcon
                    icon={['far', 'question-circle']}
                    transform="shrink-1"
                    className="icon-red"
                    style={{  marginRight: '5px' }}
                    
                  />
                   <Link
                     to={`/e-commerce/orders/order-details?id=${state.id}&activePage=${state.activePage}`}
                     state={ state}
                    >
                    <strong>{id}</strong>
                  </Link>
                </span>
              </OverlayTrigger>
            ) : (
            
              <Link
              to={`/e-commerce/orders/order-details?id=${state.id}&activePage=${state.activePage}`}
              state={ state}
                  >
                <strong>{id}</strong>
              </Link>
            )}

            {' '}
            by <strong>{name}</strong> <br />
            <Link
              to={`/e-commerce/orders/order-details?id=${state.id}&activePage=${state.activePage}`}
              state={ state}
            >
            {email}
            </Link>
          </>
        );
      }
    },
    {
      accessor: 'date',
      Header: 'Tarih',
      disableSortBy: true,
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-left'
      }
    },
    {
      accessor: 'address',
      Header: 'Şehir ve Kargo Bilgileri',
      disableSortBy: true,
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { address_city, shippingType, trackingLink } = rowData.row.original;
        return (
          <>
            {address_city}
            {trackingLink ? (
              <p>
             <a href={trackingLink} target="_blank" rel="noopener noreferrer" className="mb-0 text-500" >
             <strong style={{color:"#2C7AE5"}} >{shippingType}</strong>
                </a></p>
            ) : (
              <p className="mb-0 text-500" onClick={() => alert('Şu an takip linki oluşmamışdır.')}>
                {shippingType}
              </p>
            )}
          </>
        );
      }
      
    },
    {
      accessor: 'status',
      Header: 'Durumu',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return (
          <SoftBadge
            pill
            bg={classNames({
              secondary : status === '8', // delivered
              success: status === '7', // delivered
              danger: status === '6', // delivered
              light: status === '5',   // returned
              primary: status === '4',   // completed
              info: status === '3',  // processing
              warning: status === '2',   // pending
              dark : status === '1'   // onhold
            })}
            className="d-block"
          >
            {status === '8' && 'Teslim Edilmedi'} 
            {status === '7' && 'Teslim Edildi'} 
            {status === '6' && 'İptal Edildi'} 
            {status === '5' && 'İade Edildi'} 
            {status === '4' && 'Kargoya Verildi'}  
            {status === '3' && 'Paketlendi'} 
            {status === '2' && 'Hazırlanıyor'} 
            {status === '1' && 'Beklemede'}  
            <FontAwesomeIcon
              icon={classNames({
                check: status === '7',
                check: status === '4',
                redo: status === '3',
                stream: status === '2',
                ban: status === '1'
              })}
              transform="shrink-2"
              className="ms-1"
            />
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'amount',
      // Header: 'Tutar',
      Header: () => (
        <div 
          style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} 
          className='text-center' 
          onClick={onHeaderClick}
        >
         Tutar &nbsp; 
            {
              sortAmount === 1 && <span style={{display: 'block', fontSize:"9px", color:"#B5C2D1"}}>&#9660;</span> 
            }
            {
              sortAmount === 2 && <span style={{display: 'block', fontSize:"9px", color:"#B5C2D1"}}>&#9650;</span> 
            }
            {
              sortAmount === 3 && (
                <div>
                  <span style={{display: 'block', marginBottom:"-5px", fontSize:"8px", color:"#B5C2D1"}}>&#9650;</span> 
                  <span style={{display: 'block', fontSize:"8px", color:"#B5C2D1"}}>&#9660;</span> 
                </div>
              )
    }
        </div>
      ),
      disableSortBy: true,
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => `${rowData.row.original.amount}`
    },
    {
      accessor: 'none',
      Header: 'Mağaza',
      disableSortBy: true,
      cellProps: {
        className: 'text-left'
      },
      Cell: rowData => {
        const { store} = rowData.row.original;
        return (
          <p>{store}</p>
        );
      }
    },
    {
  
      Header: 'Fatura',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: rowData => {
        const {invoice} = rowData.row.original;
        return (
          <p style={{marginRight:"5px"}}>{invoice}</p>
        );
      }
    },
// aksiyon column
{
  Header: 'Aksiyon',
  disableSortBy: true,
  headerProps: {
    className: 'text-center'
  },
  cellProps: {
    className: 'text-center'
  },
  Cell: rowData => {
    const {id, address_city, address_text, shipment_phone, district, first_name, last_name, shipment_email} = rowData.row.original;
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target)) {
          setIsMenuOpen(false);
        }
 
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

  
    const handleModalOpen = () => {
      setIsModalOpen(true);  
      setSelectedId(id);  
    };

    const handleModalOpenCancel = () => {
      setShowCancel(true);  
      setCancelId(id);  
    };

    const handleWork = () => {
      getOrderWork(id); 
    };

    
    const handleCargoShip = () => {
      postOrderCargoShip(id); 
    };

    const handleAddressChange = () => {
      setOrderId(id)
      setAddressText(address_text)
      setCity(address_city)
      setPhone(shipment_phone)
      setFirstname(first_name)
      setLastname(last_name)
      setDistrict(district)
      setEmail(shipment_email)

      
      setShowAddress(true)
      // updateAddress(id);
    };

    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

    const handleMenuClick = (event) => {
      if (isMenuOpen) {
        setIsMenuOpen(false);
      } else {
        const triggerRect = event.currentTarget.getBoundingClientRect();
        const menuHeight = 200; 
        const menuWidth = 190; 
        
        let top = triggerRect.bottom;
        let left = triggerRect.left - menuWidth + 3; 
        
        if (top + menuHeight > window.innerHeight) {
          top = triggerRect.top - menuHeight;
        }
        if (left < 0) {
          left = 0;
        }

        setMenuPosition({ top, left });
        setIsMenuOpen(true);
      }
    };

    
    return (
      <div style={{display:'flex',  justifyContent: 'center'}}>
      <div
        ref={menuRef}
        onClick={handleMenuClick}
        style={{
          cursor: 'pointer',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          width: '30px',
        }}
      >
         <BsThreeDots style={{fontSize:'20px'}}/>
        {isMenuOpen && (
          <div
            ref={menuRef}
            className="menu"
            style={{
              position: 'fixed',
              top: `${menuPosition.top}px`,
              left: `${menuPosition.left}px`,
              backgroundColor: 'white',
              border: '1px solid #ccc',
              borderRadius: '5px',
              zIndex: 1000,
            }}

           >
            <ul style={{ listStyle: 'none', padding: 0, margin: 0, width:'190px' }}>
              <li 
                className="listItem"
                onClick={handleModalOpen}
              >
                <RiFileUploadLine style={{marginRight:'10px',  fontSize:'20px'}} />
                İrsaliye Yükle
              </li>

    
            {hasPermission('Sipariş Durumu: İptal Etme') && (
              <li
               className="listItem"
                onClick={handleModalOpenCancel}
              >
                <RiDeleteBin2Line style={{marginRight:'10px',  fontSize:'20px'}}/>
                Siparişi İptal Et
              </li>
             )}


          {hasPermission('Sipariş İş Emri Dashboard')&& (
              <li
               className="listItem"
                onClick={handleWork}
              >
                <RiShoppingCart2Line style={{marginRight:'10px',  fontSize:'20px'}}/>
                İş emri talebi oluştur
              </li>
              )}

          {hasPermission('Sipariş Durumu: Kargoya Verildi')&& (
              <li
                className="listItem"
                onClick={handleCargoShip}
              >
                <RiTruckLine style={{marginRight:'10px',  fontSize:'20px'}}/>
                Kargoya Verildi
              </li>
              )}

              {hasPermission('Sipariş Adres Düzenleme')&& (
              <li
                className="listItem"
                onClick={handleAddressChange}
              >
                <BsPencilSquare style={{marginRight:'10px',  fontSize:'20px'}}/>
                Adres Değiştirme
              </li>
              )}

            </ul>
          </div>
        )}
      </div>
      </div>
    );
  }
}
];



const {setSelectStatusModal, setStartDateModal, setEndDateModal, setCargoModal, getDataExport, startDateModal, endDateModal, setStoreModal, fulfilmentModal, setFulfilmentModal, showGif, setShowGif, setInvoicesExport, invoicesExport, myDataS3,setMyDataS3, setRegionExport, myDataResult, isPreparing, setIsPreparing, setMyDataResult, setVendorExportId, setPaidExport, setWorkExport, setSearchNameExport, setShipmentStartDateModal, setShipmentEndDateModal, shipmentEndDateModal, shipmentStartDateModal, getDataExportReturn, stopDataExport, packedStartDateModal, setPackedStartDateModal,packedEndDateModal, setPackedEndDateModal} = useOrderExportS3()

const {myDataVendor} = useVendorList()

const {vendorStore, setVendorIdVendorStore} = useVendorStore()
const {myDataRegion, setVendorIdRegion} = useInventoryListFilter()
const {shipmentCompany, setVendorIdShipment} = useShipmentCompany()

const {myDataUser, setUserVendorId} = useUserChargeBy()


const onHeaderClick = () => {
  setSortAmount((sortAmount % 3) + 1);
};

  
  // const [followersPerPage, setFollowersPerPage]=useState(10)
  // const [currentPage,setCurrentPage]=useState(1)
  const [orderList, setOrderList] = useState() 
  const [activePage, setActivePage] = useState(1)



  const location = useLocation();

  useEffect(() => {
    console.log("Active page from state:", activePage); // Debug için
    const savedActivePage = location.state?.activePage;
    if (savedActivePage) {
      console.log("Saved active page:", savedActivePage); // Debug için
      setActivePage(savedActivePage);
      setCurrentPage(savedActivePage);
    } else {
      setActivePage(1);
      setCurrentPage(1);
    }
  }, [location]);
  

  const [isOpen, setIsOpen] = useState(false);

  function hasPermission(permission) {
    if (Array.isArray(perms)) {
      return perms.includes(permission);
    }
    return false; // 
  }

  // Handler
  const handleToggle = (event) => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };
  
  const [showFast, setShowFast] = useState(false);

  // modal
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setMyDataResult(false)
    stopDataExport();
    setIsPreparing(false);
  }

  const handleCloseFast = () => {
    setShowFast(false)
  }


  const handleShow = () => setShow(true);

  const handleShowFast = () => setShowFast(true);

  const onChangeModal = datesExport => {
    const [startExport, endExport] = datesExport;
    setStartDateModal(startExport);
    setEndDateModal(endExport);
  };

  


  const onChangeShipmentModal = datesShipmentExport => {
    const [startShipmentExport, endShipmenttExport] = datesShipmentExport;
    setShipmentStartDateModal(startShipmentExport);
    setShipmentEndDateModal(endShipmenttExport);
  };


  const onChangePackedModal = datesPackedExport => {
    const [startPackedExport, endPackedExport] = datesPackedExport;
    setPackedStartDateModal(startPackedExport);
    setPackedEndDateModal(endPackedExport);
  };



  const onChangeShipment = datesShipmentExport => {
    const [startShipment, endShipmentt] = datesShipmentExport;
    setShipmentStartDate(startShipment);
    setShipmentEndDate(endShipmentt);
  };

  const onChangePacked = datesPacked => {
    const [startPacked, endPacked] = datesPacked;
    setPackedStartDate(startPacked);
    setPackedEndDate(endPacked);
  };

  

  useEffect(() => {
    if (myDataVendor && myDataVendor.length > 0) {
      const firstVendorId = myDataVendor[0].Id;
      setVendorIdVendorStore(firstVendorId);
      setVendorIdRegion(firstVendorId);
      setVendorIdShipment(firstVendorId);
      setVendorIdList(firstVendorId)
      setVendorExportId(firstVendorId)
    setUserVendorId(firstVendorId)


    }
  }, [myDataVendor]);

  const handleChangeVendor = (e)=> {
    setVendorIdVendorStore(e.target.value); 
    setVendorIdRegion(e.target.value)
    setVendorIdShipment(e.target.value)
    setVendorIdList(e.target.value)
    setUserVendorId(e.target.value)

    
  }


  const handleChangeModal = (e)=> {
    setSelectStatusModal(+e.target.value); 
    console.log("type", typeof e.target.value)
  }

  const handleChangeCargoModal = (e)=> {
    setCargoModal(e.target.value); 
  }

  const handleChangeStoreModal = (e)=> {
    setStoreModal(e.target.value); 
  }
  
  const handleChangeVendorModal = (e)=> {
    setVendorExportId(e.target.value); 
    setVendorIdVendorStore(e.target.value); 
    setVendorIdRegion(e.target.value)
    setVendorIdShipment(e.target.value)
  }

  const handleChangeRegionModal = (e)=> {
    setRegionExport(e.target.value); 
  }

  const handleExport = ()=> { 
    getDataExport(); 
    setShowGif(true);
    // setShow(false)
    setCargoModal(null)
    setSelectStatusModal(null)
    setStartDateModal(null);
    setEndDateModal(null);
    setStoreModal(null)
    setFulfilmentModal(null)
    setInvoicesExport(null)
    setRegionExport(null)
    setPaidExport(null)
    setWorkExport(null)
    setSearchNameExport(null)
    setShipmentStartDateModal(null)
    setShipmentEndDateModal(null)
    setIsPreparing(true);
    
  }

  const handleDownload = () => {
    setIsPreparing(false);
    window.location.href = myDataResult[0]?.Value;
    setShow(false)
    setMyDataResult(false)
  }



  
  const orderStatus = [
    {value:0, label:"Hepsi"},
    {value:1, label:"Beklemede"},
    {value:2, label:"Hazırlanıyor"},
    {value:3, label:"Paketlendi"},
    {value:4, label:"Kargoya verildi"},
    {value:5, label:"İade"},
    {value:6, label:"İptal"},
    {value:7, label:"Teslim Edildi"},
    {value:8, label:"Teslim Edilmedi"},
    {value:9, label:"İnceleme gerekiyor"},

]



const orderWMSStatus = [
  {value:1, label:'İş Emri Alındı'},
  {value:2, label:'Toplanıyor'},
  {value:3, label:'Paketlemeye Aktarıldı'},
  {value:4, label:'Paketlemede Okutuluyor'},
  {value:5, label:'ara durum'},
  {value:6, label:'Etiket Basıldı'},
]

const orderPaid = [
  {value : "True", label : "Ödendi"},
  {value : "False", label : "Ödenmedi"}
]

const fulfilmentStatus = [
  {value:0, label:"Hepsi"},
  {value:1, label:"Gönderilmedi"},
  {value:4, label:"Gönderildi"},

]

const invoicesStatus = [
  {value:"true", label:"Faturalı"},
  {value:"false", label:"Faturasız"},
]

const workStatus = [
  {value:"true", label:"Evet"},
  {value:"false", label:"Hayır"},
]

const storeFulFillStatus = [
  {value:"true", label:"Evet"},
  {value:"false", label:"Hayır"},
]

const InvoiceCountry = [
  {value:'Türkiye', label:'Türkiye'},
  {value:'Azerbeycan', label:'Azerbeycan'},
  {value:'Rossija', label:'Rossija'},
  
]


const handleChangeFulfilmentModal = (e)=> {
  setFulfilmentModal(+e.target.value); 
}

const handleChange = (e)=> {
  setSelectStatus(+e.target.value); 
  setSelectStatusModal(+e.target.value); 
}

const handleChangeWMS = (e)=> {
  setWmsStatus(+e.target.value); 
}

const handleChangeUser = (e)=> {
  setUserId(e.target.value); 
}

const handleChangeCountry = (e)=> {
  setInvoiceCountry(e.target.value); 
}

const handleChangeFulfilled = (e)=> {
  setFulfilled(e.target.value); 
}

const handleChangePaid = (e)=> {
  setPaid(e.target.value); 
  setPaidExport(e.target.value)
}

const handleChangeCargo = (e)=> {
  setCargo(e.target.value);
  setCargoModal(e.target.value) 
}

const handleChangeStore = (e)=> {
  setStore(e.target.value);
  setStoreModal(e.target.value) 
}

const handleChangeRegion = (e)=> {
  setInventoryRegion(e.target.value); 
  setRegionExport(e.target.value); 
}

const handleChangeinvoices = (e)=> {
  setInvoices(e.target.value); 
  setInvoicesExport(e.target.value); 
}

const handleChangeWork = (e)=> {
  setWork(e.target.value); 
  setWorkExport(e.target.value)
}

const handleChangeExportinvoices = (e)=> {
  setInvoicesExport(e.target.value); 
}


useEffect(() => {

  if (myData && Array.isArray(myData.results)) {
     
  const newList = myData?.results.map((item)=>{

    const date = new Date(item.OrderDate);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
    return {
      id : item.Id,
      name: item.Customer.FirstName+ " "+item.Customer.LastName, 
      email : item.OrderNumber === null ? "Sipariş No: -" : "Sipariş No: " + item.OrderNumber,
      date: `${day}-${month}-${year}`,
      first_name : item.ShippingAddress.FirstName,
      last_name : item.ShippingAddress.LastName,
      address_city : item.ShippingAddress.City,
      district : item.ShippingAddress.District,
      address_text : item.ShippingAddress.AddressText,
      shipment_phone : item.ShippingAddress.Phone,
      shipment_email : item.ShippingAddress.Email,
      shippingType: ((item.ShipmentCompanyText && item.ShipmentCompanyText.includes("Marketplace")) ? item.ShipmentCompanyText.replace('Marketplace', '').trim() : item.ShipmentCompanyText ? item.ShipmentCompanyText : "Kargolamayı bekliyor") + " " + (item.ShippingTrackingNumber ? item.ShippingTrackingNumber : "Henüz Kargolanmadı"),
      status: item.Status,
      amount : item.OrderAmount,
      store : item.VendorStore.Store.Name,
      trackingLink :item.ShipmentTrackingLink,
      invoice : item.Invoices[0]?.CreatedAt ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/><path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/></svg>,
      note : item.Note
  
    }
  
  })
  setOrderList(newList)
}
}, [myData, firstName])


const handleChangeDate = event => {
  setSelectedOption(event.target.value);

  let start, end;

  switch(event.target.value) {
    case "1": // Bugün
      start = new Date();
      end = new Date();
      break;

    case "2": // Bu hafta
      end = new Date();
      start = new Date();
      start.setDate(start.getDate() - start.getDay() + (start.getDay() === 0 ? -6 : 1));
      break;   

    case "3": // Bu ay
      end = new Date();
      start = new Date();
      start.setDate(1); // Ayın ilk günü
      break;
  
    default:
      // Özel tarih seçilirse başlangıç ve bitiş tarihleri korunur
      break;
  }

  setStartDate(start);
  setEndDate(end);

  setStartDateModal(start);
  setEndDateModal(end);

};

const [isOpenDate, setIsOpenDate] = useState(true);

const onChange = dates => {

  const [start, end] = dates;

  if (selectedOption === "4") {
    setStartDate(start);
    setEndDate(end);

    setStartDateModal(start);
    setEndDateModal(end);
  }
  if (start && end) {
    setIsOpenDate(false);  // tarihler seçildiyse datepicker'ı kapat
  }
};




const [selectedOption, setSelectedOption] = useState(null);


useEffect(() => {
  if (selectedOption === "4") {
    setIsOpenDate(true);
  } else {
    setIsOpenDate(false);
  }
}, [selectedOption]);


const [isModalOpen, setIsModalOpen] = useState(false);
const [selectedId, setSelectedId] = useState(null);
const [selectedFile, setSelectedFile] = useState(null);
const { uploadPDF, errorMessage } = useOrderPdfUpload();  // useHook

const handleModalClose = () => {
  setIsModalOpen(false);
};

const handleFileChange = (e) => {
  setSelectedFile(e.target.files[0]);
};

const handleFileUpload = () => {
  if (selectedFile) {
    uploadPDF(selectedFile, selectedId);  // Dosyayı ve id'yi useHook'a gönder
    handleModalClose(); // Modalı kapat
  }
};

const [showCancel, setShowCancel] = useState(false);
const handleCloseCancel = () => setShowCancel(false);
const [cancelId, setCancelId] = useState()
const {getCancelData}  = useOrderCancel()


const handleCancel = () => {
  getCancelData(cancelId)

}
const handleChangeName = (e) => {
  setSearchName(e.target.value)
  setSearchNameExport(e.target.value)
}



const [selectedStatus, setSelectedStatus] = useState("");

const handleButtonClick = (statusValue) => {
  setSelectedStatus(statusValue) // buttongroup
  setSelectStatus(+statusValue); // useHook
  console.log("statusValue",statusValue)

};


// address update modal

const [showAddress, setShowAddress] = useState(false)
const handleCloseAddress = () => setShowAddress(false)
const [addressText, setAddressText] = useState()
const [city, setCity] = useState()
const [phone, setPhone] = useState()
const [lastname, setLastname] = useState()
const [firstname, setFirstname] = useState()
const [district, setDistrict] = useState()
const [email, setEmail] = useState()





const [address, setAddress] = useState({
  address_text: "",
  address_city: ""
});




const handleKeyDown = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault();
  }
};

function toUpperCase(str) {
  return str.replace(/ğ/g, "Ğ")
            .replace(/ü/g, "Ü")
            .replace(/ş/g, "Ş")
            .replace(/ı/g, "I")
            .replace(/i/g, "İ")
            .replace(/ö/g, "Ö")
            .replace(/ç/g, "Ç")
            .toUpperCase();
}

const [loading, setLoading] = useState(false);
const handleFormAddressSubmit = (e) => {
  e.preventDefault();
  setLoading(true); 
  
  const data = {
    AddressText: addressText,
    City: toUpperCase(city),
    Phone: phone,
    LastName: lastname,
    FirstName: firstname,
    District: toUpperCase(district),
    Email: email
  };
  
  updateAddress(data, (success) => {
    setLoading(false); 
    if (success) {
      setShowAddress(false);
      getData()
    } else {
      console.log("Güncelleme başarısız");
    }
  });


}





  return (
    <AdvanceTableWrapper
      columns={columns}
      data={orderList || []} 
      // data={orderList?.length>0 && orderList} 
      selection
      sortable
      pagination
      perPage={50}
    >
     
      <Card className="mb-3">
        <Card.Header>

      <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Siparişler</h5>
            </div>


            <div style={{display:"flex", justifyContent:'right'}}>

            <div className="mb-3" style={{marginRight:"15px"}}>
                    <InputGroup>
                      <Form.Control  type="text" placeholder="id, isim, numarası..."  onChange={(e)=>setSearch(e.target.value)}/>
                      <InputGroup.Text><Search/></InputGroup.Text>
                    </InputGroup>
            </div>

            <div >
              <OrdersTableHeader table />
            </div>

            { hasPermission('Dashboard Sipariş Export') ?
            <div style={{ marginRight:"8px"}}>
                <IconButton
                  variant="falcon-default"
                  size="m"
                  icon="external-link-alt"
                  transform="shrink-3"
                  onClick={handleShow}
                  style={{width:'145px', fontSize:'15px'}}
                >
                  <span className="d-none d-sm-inline-block ms-1">Export</span>
                </IconButton>
                </div>
          : ("") }
                

      <Dropdown  show={isOpen} onToggle={handleToggle}>
        <Dropdown.Toggle
           bsPrefix="toggle"
           as={Link}
          //  to=""
          onClick={handleToggle}
           className={classNames('px-0 nav-link')}>
     
            <IconButton
              variant="falcon-default"
              size="m"
              icon="fa-sharp fa-light fa-filter-list"
              transform="shrink-3"
              style={{width:'145px', fontSize:'15px'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-filter-right" viewBox="0 0 16 16">
                <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5z"/>
              </svg>
                  <span className="d-none d-sm-inline-block ms-1">Filtre</span>
            </IconButton>
        </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret" style={{minWidth: '300px', width: '300px'}}>
          <Card className="shadow-none shadow-show-xl scrollbar">
            <Card.Header className="bg-light d-none d-xl-block">
              <h6 className="mb-0">Filtre</h6>
            </Card.Header>
              <Card.Body>
                <Form>

                <div className="mb-3" >
                    <InputGroup>
                      <Form.Control  type="text" placeholder="ürün ismi..."  
                      onChange={handleChangeName}
                     />
                      <InputGroup.Text><Search/></InputGroup.Text>
                      </InputGroup>
            </div>

            { hasPermission('Dashboard Çoklu Satıcı Görme') ?  
                  <div >
                  <Form.Label className="mb-1 mt-2 fs--1" style={{color:"#5E6E82"}}>Satıcı</Form.Label>
                    { myDataVendor?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeVendor}>
                     <option value="" disabled>Satıcı</option>
                        { myDataVendor?.map((item, i)=>{
                              return <option key={i} value={item.Id}>{item.vendor_name}</option>
                        })}       
                </Form.Select>}
            </div>
            : "" }

            <div className="mb-2">
                    <Form.Label className="mb-1 mt-2 fs--1" style={{color:"#5E6E82"}}>Mağazalar</Form.Label>
                    { vendorStore?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeStore}>
                            <option value="" >Mağaza</option>
                                { vendorStore?.map((item, i)=>{
                                      return <option key={i} value={item.Store__Name}>{item.Store__Name}</option>
                                })}       
                        </Form.Select>}
                  </div>
              <div className="mb-2">
                  <Form.Label className="mb-1 fs--1" style={{color:"#5E6E82"}}>Sipariş Tarihi</Form.Label>

                  <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeDate}>
                  <option value="" disabled selected>Tarih</option>
                  <option key={1} value="1">Bugün</option>
                  <option key={2} value="2">Bu hafta</option>
                  <option key={2} value="3">Bu ay</option>
                  <option key={3} value="4">Tarih seç</option>
                              </Form.Select> 

                {selectedOption === "4" && 
                  <DatePicker
                    showIcon
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    formatWeekDay={day => day.slice(0, 3)}
                    endDate={endDate}
                    selectsRange
                    dateFormat="MMM dd"
                    className='form-control'
                    placeholderText='tarih giriniz'
                    open={isOpenDate}
                    onCalendarClose={() => setIsOpenDate(false)}
                    onCalendarOpen={() => setIsOpenDate(true)}
                  />
                }
              </div>

              <div className="mb-2">
                    <Form.Label className="mb-1 mt-2 fs--1" style={{color:"#5E6E82"}}>Sipariş Durumu</Form.Label>
                    <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChange}>
                            <option value=""  disabled selected>Durumu</option>
                                {orderStatus?.map((item, i)=>{
                                      return <option  key={i} value={item.value}>{item.label}</option>
                                })}
                        </Form.Select> 
                  </div>

                  <div className="mb-2">
                    <Form.Label className="mb-1 mt-2 fs--1" style={{color:"#5E6E82"}}>WMS Durumu</Form.Label>
                    <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeWMS}>
                            <option value=""  disabled selected>Durumu</option>
                                {orderWMSStatus?.map((item, i)=>{
                                      return <option  key={i} value={item.value}>{item.label}</option>
                                })}
                        </Form.Select> 
                  </div>

                  <div className="mb-2">
                    <Form.Label className="mb-1 mt-2 fs--1" style={{color:"#5E6E82"}}>Kargo Firması</Form.Label>
                    { shipmentCompany?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeCargo}>
                            <option value="" >Kargo</option>
                                { shipmentCompany?.map((item, i)=>{
                                      return <option key={i} value={item.ShipmentCompanyText}>{item.ShipmentCompanyText}</option>
                                })}       
                        </Form.Select>}
                  </div>

                    <div style={{fontSize:"14px"}} className="mb-2">
                        <Form.Label className="mb-1 fs--1" style={{color:"#5E6E82"}}>Kargo Tarihi</Form.Label>
                            
                            <DatePicker
                            showIcon
                            selected={shipmentStartDate}
                            onChange={onChangeShipment}
                            startDate={shipmentStartDate}
                            formatWeekDay={day => day.slice(0, 3)}
                            endDate={shipmentEndDate}
                            selectsRange
                            dateFormat="MMM dd"
                            className='form-control'
                            placeholderText='kargo tarihi'
                            />
                      </div>

                      <div style={{fontSize:"14px"}}>
                        <Form.Label className="mb-1 fs--1" style={{color:"#5E6E82"}}>Paketleme Tarihi</Form.Label>
                            
                            <DatePicker
                            showIcon
                            selected={packedStartDate}
                            onChange={onChangePacked}
                            startDate={packedStartDate}
                            formatWeekDay={day => day.slice(0, 3)}
                            endDate={packedEndDate}
                            selectsRange
                            dateFormat="MMM dd"
                            className='form-control'
                            placeholderText='paketleme tarihi'
                            />
                      </div>

                  <div className="mb-2">
                    <Form.Label className="mb-1 mt-2 fs--1" style={{color:"#5E6E82"}}>Platform Fulfill Mi?</Form.Label>
                    <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeFulfilled}>
                            <option value=""  >Durumu</option>
                                {storeFulFillStatus?.map((item, i)=>{
                                      return <option  key={i} value={item.value}>{item.label}</option>
                                })}
                        </Form.Select> 
                  </div>

                  <div className="mb-2">
                    <Form.Label className="mb-1 mt-2 fs--1" style={{color:"#5E6E82"}}>Fatura Adresi Ülke</Form.Label>
                    <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeCountry}>
                            <option value=""  >Ülke</option>
                                {InvoiceCountry?.map((item, i)=>{
                                      return <option  key={i} value={item.value}>{item.label}</option>
                                })}
                        </Form.Select> 
                  </div>

                  <div className="mb-2">
                    <Form.Label className="mb-1 mt-2 fs--1" style={{color:"#5E6E82"}}>Sipariş Sorumlusu</Form.Label>
                    <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeUser}>
                            <option value=""  disabled selected>Kullanıcı</option>
                                {myDataUser?.map((item, i)=>{
                                      return <option  key={i} value={item.id}>{item.username}</option>
                                })}
                        </Form.Select> 
                  </div>
                  
                  
                  <div className="mb-2">
                    <Form.Label className="mb-1 mt-2 fs--1" style={{color:"#5E6E82"}}>Ödeme Durumu</Form.Label>
                    <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangePaid}>
                            <option value=""  >Durumu</option>
                                {orderPaid?.map((item, i)=>{
                                      return <option  key={i} value={item.value}>{item.label}</option>
                                })}
                        </Form.Select> 
                  </div>
                  <div className="mb-2">
                    <Form.Label className="mb-1 mt-2 fs--1" style={{color:"#5E6E82"}}>Fatura Durumu</Form.Label>
                    <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeinvoices}>
                            <option value=""  >Durumu</option>
                                {invoicesStatus?.map((item, i)=>{
                                      return <option  key={i} value={item.value}>{item.label}</option>
                                })}
                        </Form.Select> 
                  </div>
                  <div className="mb-2">
                    <Form.Label className="mb-1 mt-2 fs--1" style={{color:"#5E6E82"}}>Depolar</Form.Label>
                    { myDataRegion?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeRegion}>
                            <option value="" >Depo</option>
                                { myDataRegion?.map((item, i)=>{
                                      return <option key={i} value={item.Region__Name}>{item.Region__Name}</option>
                                })}       
                        </Form.Select>}
                  </div>
                  <div className="mb-2">
                    <Form.Label className="mb-1 mt-2 fs--1" style={{color:"#5E6E82"}}>İş Emri Durumu</Form.Label>
                    <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeWork}>
                            <option value="">İş Emri</option>
                                {workStatus?.map((item, i)=>{
                                      return <option  key={i} value={item.value}>{item.label}</option>
                                })}
                        </Form.Select> 
                  </div>

                  <div style={{display:'flex',justifyContent:'center', marginTop:'20px'}}>    
                  <Button variant="primary" onClick={handleShowFast}>
                    Hızlı İndir
                  </Button>
                  </div>
                 
              </Form>
            </Card.Body>
        </Card>
      </Dropdown.Menu>
      </Dropdown>
    </div>   
    </div>

    <div style={{marginTop:'10px'}}>
          <ButtonGroup size="sm" style={{ borderRadius:'0'}}>
            {orderStatus.map((status) => (
              <Button
                key={status.value}
                variant="outline-secondary"
                className="custom-btn"
                onClick={() => handleButtonClick(status.value)}
                style={{ 
                  fontSize: '0.8em', 
                  border: 'none', 
                  boxShadow: 'none',
                  borderRadius: '0',
                  borderBottom: selectedStatus === status.value ? '3px solid blue' : 'none',
                }}
              >
                {status.label}
              </Button>
            ))}
          </ButtonGroup>

          </div>

 
</Card.Header>

        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
            style={{backgroundColor:'red'}}
          />


        <div style={{
            position: 'fixed',
            top: '50%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
          }}>
            {showOrderGif && <img src={gifOrder} style={{width:'50%'}} alt="loading gif" />}
          </div>
        <div style={{
            position: 'fixed',
            top: '50%',
            left: '60%',
            transform: 'translate(-50%, -50%)',
          }}>
            {showGif && <img src={gifOrder} style={{width:'50%'}} alt="export gif" />}
          </div>
        </Card.Body>
        <Card.Footer>
          {/* <AdvanceTablePagination table /> */}
          <Pagination pages={totalPages} setCurrentPage={setCurrentPage} activePage={activePage} setActivePage={setActivePage} setIsLoading={setIsLoading}/>
        </Card.Footer>
       
      </Card>
      <span style={{marginRight:"5px",marginLeft:"5px"}}>Sipariş sayısı</span>
      <Button variant={followersPerPage === 10 ? 'primary' : ''} onClick={() => setFollowersPerPage(10)}>10</Button>
      <Button variant={followersPerPage === 25 ? 'primary' : ''} onClick={() => setFollowersPerPage(25)}>25</Button>
      <Button variant={followersPerPage === 50 ? 'primary' : ''} onClick={() => setFollowersPerPage(50)}>50</Button>
      <span style={{marginLeft:"5px"}}> </span>


      <Modal 
      show={show} 
      onHide={handleClose}
      size="xl"
      
      >
        <Modal.Header closeButton>
          <Modal.Title>
          <div style={{display:'flex', justifyContent:'space-between'}} > 
          <div>Sipariş Listesi</div>
            

          

            </div>
          </Modal.Title>
            </Modal.Header>
              <Modal.Body>
          
        
          
        <div style={{display:'flex', justifyContent:'center'}} >
       
        <div style={{marginRight:'8px'}}>
                    { myDataVendor?.length> 1 &&    
                    
                    < Form.Select  className='form-control' style={{fontSize:"15px", width:"145px"}} onChange={handleChangeVendorModal}>
                  
                     <option value="">Satıcı</option>
                        { myDataVendor?.map((item, i)=>{
                              return <option key={i} value={item.Id}>{item.vendor_name}</option>
                        })}       
                </Form.Select>}
            </div>
   
          <div style={{marginRight:"8px", fontSize:"14px", width:"190px"}}>
                <DatePicker
                showIcon
                selected={startDateModal}
                onChange={onChangeModal}
                startDate={startDateModal}
                formatWeekDay={day => day.slice(0, 3)}
                endDate={endDateModal}
                selectsRange
                dateFormat="MMM dd"
                className='form-control'
                placeholderText='sipariş tarihi'
                />
          </div>

          <div style={{marginRight:"8px", fontSize:"14px", width:"190px"}}>
                <DatePicker
                showIcon
                selected={shipmentStartDateModal}
                onChange={onChangeShipmentModal}
                startDate={shipmentStartDateModal}
                formatWeekDay={day => day.slice(0, 3)}
                endDate={shipmentEndDateModal}
                selectsRange
                dateFormat="MMM dd"
                className='form-control'
                placeholderText='kargo tarihi'
                />
          </div>

         
          <div style={{marginRight:"8px", fontSize:"14px", width:"190px"}}>
              <DatePicker
              showIcon
              selected={packedStartDateModal}
              onChange={onChangePackedModal}
              startDate={packedStartDateModal}
              formatWeekDay={day => day.slice(0, 3)}
              endDate={packedEndDateModal}
              selectsRange
              dateFormat="MMM dd"
              className='form-control'
              placeholderText='paketleme tarihi'
              />
        </div>

          <div style={{marginRight:'8px'}}>
                    { vendorStore?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:"145px"}} onChange={handleChangeStoreModal}>
                     <option value="" >Mağaza</option>
                        { vendorStore?.map((item, i)=>{
                              return <option key={i} value={item.Store__Name}>{item.Store__Name}</option>
                        })}       
                </Form.Select>}
            </div>


          <div style={{marginRight:"8px"}}>
              <Form.Select  className='form-control' style={{fontSize:"15px", width:"130px"}} onChange={handleChangeModal}>
                  <option value=""  disabled selected>Statüsü</option>
                      {orderStatus?.map((item, i)=>{
                            return <option  key={i} value={item.value}>{item.label}</option>
                      })}
              </Form.Select> 
          </div>

          <div style={{marginRight:"8px"}}>
              <Form.Select  className='form-control' style={{fontSize:"15px", width:"130px"}} onChange={handleChangeFulfilmentModal}>
                  <option value=""  disabled selected>Durumu</option>
                      {fulfilmentStatus?.map((item, i)=>{
                            return <option  key={i} value={item.value}>{item.label}</option>
                      })}
              </Form.Select> 
          </div>

          <div style={{marginRight:"8px"}}>
                  { shipmentCompany?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:"112px"}} onChange={handleChangeCargoModal}>
                  <option value="" >Kargo</option>
                      { shipmentCompany?.map((item, i)=>{
                            return <option key={i} value={item.ShipmentCompanyText}>{item.ShipmentCompanyText}</option>
                      })}       
              </Form.Select>}
          </div>
          <div className="mb-2">
                    <Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeExportinvoices}>
                            <option value="">Fatura</option>
                                {invoicesStatus?.map((item, i)=>{
                                      return <option  key={i} value={item.value}>{item.label}</option>
                                })}
                        </Form.Select> 
                  </div>
                  <div style={{marginLeft:"8px"}}>
                      { myDataRegion?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:"112px"}} onChange={handleChangeRegionModal}>
                      <option value="" >Depo</option>
                          { myDataRegion?.map((item, i)=>{
                                return <option key={i} value={item.Region__Name}>{item.Region__Name}</option>
                          })}       
                  </Form.Select>}
                  </div>

</div>
               
          </Modal.Body>
          <Modal.Footer>
          <Row style={{width:'100%'}}>      
  <Row>
  { myDataVendor?.length> 1 ? <p>Satıcı ismi seçilmesi ZORUNLUDUR!</p> : ""}
    <p>Tarih aralığı geniş sipariş listesinin indirilmesi 1-2 dk bulabilir.</p>
    <p>Siparişiniz hazırlanıyor yazısından sonra tekrar Hazırla butonuna basmayınız.</p>

    <Col className="d-flex justify-content-end mb-3">
      <Button variant="secondary" onClick={handleClose} style={{marginRight:'10px'}}>
        Vazgeç
      </Button>

{!isPreparing && (
  <Button variant="primary" onClick={handleExport}>
        Hazırla
      </Button>


) }
      

      
    </Col>
  </Row>
  {isPreparing && (!myDataResult || !myDataResult.error) && 
  
  <div>
  <div>Sipariş listeniz hazırlanıyor, lütfen bekleyin...</div>
  <img src={gif_s3} style={{width:'50%'}} alt="export gif" />
  </div>
  
  }
  {myDataResult && myDataResult[0]?.Value?.length > 0  && 
  <Row className="justify-content-center mt-5" style={{width:'100%'}}>
    <Col style={{display: "flex", justifyContent: "center"}}>
    <Alert variant="success" style={{marginRight:'10px', height:'40px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

        {/* {myDataS3?.message} */}
        Sipariş listesiniz hazır indirebilirsiniz.
      </Alert>
      <Button 
  variant="primary" 
  style={{width:'150px', height:'40px'}}
  // onClick={() => window.location.href = myDataS3?.download_url}
  onClick={handleDownload}
>
  İndir
</Button>

    </Col>
  </Row>

  }
   </Row>
      </Modal.Footer>
      </Modal>


      <Modal 
      show={showFast} 
      onHide={handleCloseFast}
      size="l"
      >
        <Modal.Header closeButton>
          <Modal.Title>Sipariş Listesi</Modal.Title>
          </Modal.Header>
            <Modal.Body>
            </Modal.Body>
          <Modal.Footer>
          <Row style={{width:'100%'}}>      
  <Row>
  { myDataVendor?.length> 1 ? <p>Satıcı ismi seçilmesi ZORUNLUDUR!</p> : ""}
    <p>Tarih aralığı geniş sipariş listesinin indirilmesi 1-2 dk bulabilir.</p>
    <p>Siparişiniz hazırlanıyor yazısından sonra tekrar Hazırla butonuna basmayınız.</p>

    <Col className="d-flex justify-content-end mb-3">
      <Button variant="secondary" onClick={handleCloseFast} style={{marginRight:'10px'}}>
        Vazgeç
      </Button>
      <Button variant="primary" onClick={handleExport}>
        Hazırla
      </Button>
    </Col>
  </Row>
  {isPreparing && (!myDataResult || !myDataResult.error) && 
  
  <div>
  <div>Sipariş listeniz hazırlanıyor, lütfen bekleyin...</div>
  <img src={gif_s3} style={{width:'50%'}} alt="export gif" />
  </div>
  
  }
  {myDataResult && myDataResult[0]?.Value?.length > 0  && 
  <Row className="justify-content-center mt-5" style={{width:'100%'}}>
    <Col style={{display: "flex", justifyContent: "center"}}>
    <Alert variant="success" style={{marginRight:'10px', height:'40px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

        {/* {myDataS3?.message} */}
        Sipariş listesiniz hazır indirebilirsiniz.
      </Alert>
      <Button 
  variant="primary" 
  style={{width:'150px', height:'40px'}}
  // onClick={() => window.location.href = myDataS3?.download_url}
  onClick={handleDownload}
>
  İndir
</Button>

    </Col>
  </Row>

  }
   </Row>
      </Modal.Footer>
      </Modal>



      <Modal show={isModalOpen} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>PDF Yükle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="file" accept="application/pdf" onChange={handleFileChange} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Kapat
          </Button>
          <Button variant="primary" onClick={handleFileUpload}>
            Yükle
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCancel} onHide={handleCloseCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Bilgilendirme</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          İptal etmek istediğinize emin misiniz?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCancel}>
            Hayır
          </Button>
          <Button variant="danger" onClick={handleCancel}>
            İptal Et
          </Button>
        </Modal.Footer>
      </Modal>

<Modal show={showAddress} onHide={handleCloseAddress}>
  <Modal.Header closeButton>
    <Modal.Title>Teslimat Bilgileri</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form onSubmit={handleFormAddressSubmit} onKeyDown={handleKeyDown}>
      <Form.Group controlId="formDepo" className="mb-3">
        <Form.Label>Teslimat Bilgileri</Form.Label>
        <div style={{display:'flex', justifyContent:'space-between', marginBottom:'10px'}}>
          <Form.Control 
            placeholder="isim..." 
            style={{marginRight:'10px'}}
            onChange={(e)=>setFirstname(e.target.value)}
            value={firstname || ''}
          />
          <Form.Control 
            placeholder="soyisim..." 
            onChange={(e)=>setLastname(e.target.value)}
            value={lastname || ''}
          />
        </div>
        <div style={{display:'flex', justifyContent:'space-between'}}>
          <Form.Control 
            required 
            placeholder="adres..." 
            style={{ marginBottom:'10px'}}
            onChange={(e)=>setAddressText(e.target.value)}
            value={addressText || ''}
          />
        </div>
        <div style={{display:'flex', justifyContent:'space-between'}}>
          <Form.Control 
            placeholder="telefon..." 
            style={{marginRight:'10px'}}
            onChange={(e)=>setPhone(e.target.value)}
            value={phone || ''}
          />
          <Form.Control 
            placeholder="e-mail..." 
            onChange={(e)=>setEmail(e.target.value)}
            value={email || ''}
          />
        </div>
        <div style={{display:'flex', justifyContent:'space-between', marginTop:'10px'}}>
          <Form.Control 
            placeholder="ilçe..." 
            style={{marginRight:'10px'}}
            onChange={(e)=>setDistrict(e.target.value)}
            value={district || ''}
          />
          <Form.Control 
            placeholder="il..." 
            onChange={(e)=>setCity(e.target.value)}
            value={city || ''}
          />
        </div>
      </Form.Group>

      <div style={{marginTop:'40px'}}>
        <p style={{fontSize:'15px'}}>Adres değişikliği <span style={{fontWeight:'bold'}}>kargo ve teslimat süreçlerini</span> etkilemektedir.</p>
        <p style={{fontSize:'15px'}}>Gerekli olmadığı sürece <span style={{fontWeight:'bold'}}>değiştirmeyiniz!</span> </p>
      </div>

      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseAddress}>
          İptal Et
        </Button>
        <Button
            variant="danger"
            type="submit"
            className="button-container fixed-width-button"
            disabled={loading}
          >
            {loading ? <img src={gifOrder} alt="loading..." className="button-img" /> : "Onayla"}
          </Button>
      </Modal.Footer>
    </Form>
  </Modal.Body>
</Modal>

    
    </AdvanceTableWrapper>
  );
};

export default Orders;





