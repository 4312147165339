import React, { useContext, useEffect, useState } from 'react';
import { Card, Dropdown, Form, InputGroup, Row, Col, Alert } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
// import OrdersTableHeader from './OrdersTableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import PaginationProductAll from './PaginationProductAll';
import ProductTableHeaderAll from './ProductTableHeaderAll';
import useProductListAll from 'hooks/useProductListAll';
import IconButton from 'components/common/IconButton';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Search } from 'react-bootstrap-icons';
import gif from 'assets/img/gif_export_s3.gif'
import { useProductUpload } from 'hooks/useProductUpload';
import { useRef } from 'react';
import useProductExampleExport from 'hooks/useProductExampleExport';
import { PermsContext } from 'context/PermsContext';
import useVendorList from 'hooks/useVendorList';
import useProductAllExportS3 from 'hooks/useProductAllExportS3';





const ProductList = () => {


  const columns = [
    {
      // accessor: 'name',
      Header: 'Ürün',
      disableSortBy: true,
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { image, barcode, sku, id } = rowData.row.original;
        const state = {barcode, sku, id}
        return (
          <>
    
          <Link 
          to={`/e-commerce/product/product-details?barcode=${state.barcode}&sku=${state.sku}&id=${state.id}`}
          state={state}>
          <img style={{width:"40px", maxWidth:"100%",  transition:"transform 0.5s"}} 
            src={image} alt=""
            onMouseOver={(e) => e.target.style.transform="scale(1.2)"}
            onMouseOut={(e) => e.target.style.transform="scale(1)"}
            />
            </Link>
          </>
        );
      }
    },
  
    {
      accessor: 'name',
      disableSortBy: true,
      Header: <span style={{marginLeft:"15px"}}>İsim</span> ,
      Cell: rowData => {
        const { title, barcode, sku, id } = rowData.row.original;
        const state = {barcode, sku, id}
        return (
          <>
  
         <Link 
          to={`/e-commerce/product/product-details?barcode=${state.barcode}&sku=${state.sku}&id=${state.id}`} 
          state={state}>
             <td style={{color:"#637386"}}
                    title={title}
                    onMouseOver={(e) => (e.currentTarget.title = title)}
                    >
                {title.length > 60
                        ? title.slice(0, 60).concat("...")
                        : title}
            
        </td>
        </Link>
          </>
        );
      }
    },
    {
      accessor: 'price',
      disableSortBy: true,
      Header: 'Fiyatı',
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-left fs-0'
      },
      Cell: rowData => {
        const { price } = rowData.row.original;
        return (
          <>
         <p style={{ fontSize: "15px" }} >{ price }</p>
        
          </>
        )
      }
    },
    {
      accessor: 'status',
      disableSortBy: true,
      Header: 'Barkodu',
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center fs-0'
      },
      Cell: rowData => {
        const { barcode } = rowData.row.original;
        return (
          <>
         <p style={{ fontSize: "15px" }} >{ barcode }</p>
        
          </>
        )
      }
    },
    {
      accessor: 'date',
      Header: 'SKU',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center fs-0'
      },
      Cell: rowData => {
        const { sku } = rowData.row.original;
        return (
          <>
      
         <p style={{ fontSize: "15px" }} >{ sku }</p>
          </>
        )
      }
    }
  ];

const {myData, setPage, setSearch, setStock, stock, currentPage,setCurrentPage, followersPerPage, setFollowersPerPage, totalPages,sortState, setSortState, setVendorId, vendorId} = useProductListAll()
const {getDataExport, showGif, setShowGif, myDataResult, isPreparing, setIsPreparing, setMyDataResult, setVendorIdExport} = useProductAllExportS3()

const {perms} = useContext(PermsContext)
const {myDataVendor} = useVendorList()

const navigate = useNavigate()



  function hasPermission(permission) {
    return perms?.includes(permission);
  }



const onHeaderClick = () => {
  setSortState((sortState % 3) + 1);
};


const [activePage, setActivePage] = useState(1)

useEffect(() => {

  setPage(activePage)

}, [activePage])


const [isOpen, setIsOpen] = useState(false);

// Handler
const handleToggle = () => {
  setIsOpen(!isOpen);
};


const { uploadFile, errorMessage, setVendorIdUpload } = useProductUpload()
const fileInput = useRef(null);
const [fileName, setFileName] = useState('');


const handleUploadClick = () => {
  if (fileInput.current.files.length > 0) {
      setFileName(fileInput.current.files[0].name);
      uploadFile(fileInput.current.files[0]);
      setUploadShow(false)
    
  } else {
      console.log("No file chosen!");
  }
  setFileName("")
};

const {getDataProductExport} = useProductExampleExport()

const handleProductExport = () => {
  getDataProductExport()

}

const handleChangeVendor = (e)=> {
  setVendorIdUpload(e.target.value); 
  setVendorIdExport(e.target.value); 
}



useEffect(() => {
  if (myDataVendor && myDataVendor.length > 0) {
    const firstVendorId = myDataVendor[0].Id;
    
    setVendorId(firstVendorId)
    setVendorIdExport(firstVendorId)
  }
}, [myDataVendor]);




const handleChangeVendorList = (e)=> {
  
  setVendorId(e.target.value)
  // setVendorIdBarcode(e.target.value)
  // setSubVendorId(e.target.value)
}



// modal

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const [uploadShow, setUploadShow] = useState(false)

const handleUploadClose = () => setUploadShow(false);
const handleUploadShow = () => setUploadShow(true);




const handleExport = ()=> { 
  getDataExport(); 
  setShowGif(true);
  // setShow(false)

  

  setIsPreparing(true);
}

const handleDownload = () => {
  setIsPreparing(false);
  window.location.href = myDataResult[0]?.Value;
  setShow(false)
  setMyDataResult(false)
}




const orderList = myData?.results.map((item)=>{

  const priceInTL = new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY' }).format(item.Price);


  return {
    id : item.Id,
    title: item.Title, 
    barcode : item.barcodes__Barcode,
    sku: item.SKU,
    stock: Number(item.InventoryCount) || 0,
    image : item.Image,
    comitted: item.sub_product ?  "set ürünü" : item.InventoryCount - item.CommittedCount ,
    reserve : item.sub_product ?  "set ürünü" : item.CommittedCount,
    price :  item.Price ? priceInTL : "-", 

  }
})


const selectStock = [
  {value:99, label:"< 100"},
  {value:101, label:"100-500"},
  {value:501, label:"+ 500"},

]

const handleChange = (e)=> {
  setStock(e.target.value); 
  console.log("stock", stock)
}



  return (
    <AdvanceTableWrapper
      columns={columns}
      data={orderList || []} 
      selection
      sortable
      pagination
      perPage={50}
    >
      <Card className="mb-3">
        <Card.Header>
        <div style={{display:"flex", justifyContent:"space-between"}}>
        <div>
            <ProductTableHeaderAll table />
        </div>
        <div style={{display:"flex", justifyContent:'right'}}>
            <div className="mb-3" style={{marginRight:"8px"}}>
                  <InputGroup>
                    <Form.Control  type="text" placeholder="barkod, sku, isim..."  onChange={(e)=>setSearch(e.target.value)}/>
                    <InputGroup.Text><Search/></InputGroup.Text>
              </InputGroup>
                  </div>

                  {hasPermission('Stok Excel Export') && (
                <div style={{marginRight:"8px"}}>
                  <IconButton
                        variant="falcon-default"
                        size="m"
                        icon="external-link-alt"
                        transform="shrink-3"
                        onClick={handleShow}
                      >
                        <span className="d-none d-sm-inline-block ms-1">Export</span>
                      </IconButton>
                      </div>
               )} 
                  {hasPermission('Can add Ürün') && (   
                      <div style={{marginRight:"8px"}}>
                <IconButton
                  variant="falcon-default"
                  size="m"
                  icon="plus"
                  transform="shrink-3"
                  // onClick={handleUploadShow} 
                  onClick={()=>navigate("/e-commerce/product/product-add")}
                  style={{width:'195px', fontSize:'15px'}}
                >
                  <span className="d-none d-sm-inline-block ms-1">Ürün Ekle</span>
                </IconButton>
                </div>
                   )} 
                   { hasPermission('Dashboard Çoklu Satıcı Görme') ?  
                  <div style={{marginBottom:'10px'}}>
         
                    { myDataVendor?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeVendorList}>
                     <option value="" disabled>Satıcı</option>
                        { myDataVendor?.map((item, i)=>{
                              return <option key={i} value={item.Id}>{item.vendor_name}</option>
                        })}       
                </Form.Select>}
            </div>
            : "" }
      </div>
    </div>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
           <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
            {showGif && <img src={gif} alt="loading gif" />}
          </div>
        </Card.Body>
        <Card.Footer>
          {/* <AdvanceTablePagination table /> */}
          <PaginationProductAll pages={totalPages} activePage={activePage} setActivePage={setActivePage} setCurrentPage={setCurrentPage}/>
        </Card.Footer>
      </Card>
      <span style={{marginRight:"5px",marginLeft:"5px"}}>Ürün sayısı</span>
      <Button variant={followersPerPage === 10 ? 'primary' : ''} onClick={() => setFollowersPerPage(10)}>10</Button>
      <Button variant={followersPerPage === 25 ? 'primary' : ''} onClick={() => setFollowersPerPage(25)}>25</Button>
      <Button variant={followersPerPage === 50 ? 'primary' : ''} onClick={() => setFollowersPerPage(50)}>50</Button>
      <span style={{marginLeft:"5px"}}> </span>

     <Modal 
      show={show} 
      onHide={handleClose}
  
      >
        <Modal.Header closeButton>
          <Modal.Title>Ürün Listesi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Lütfen Satıcı seçimi yaparak ürün listesini indiriniz.</p>
       <div style={{marginRight:'8px'}}>
                    { myDataVendor?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:"145px"}} onChange={handleChangeVendor}>
                     <option value="">Satıcı</option>
                        { myDataVendor?.map((item, i)=>{
                              return <option key={i} value={item.Id}>{item.vendor_name}</option>
                        })}       
                </Form.Select>}
            </div>

          </Modal.Body>
        <Modal.Footer>
        <Row style={{width:'100%'}}>      
  <Row>

  
    <p>Ürün Listeniz hazırlanıyor yazısından sonra tekrar Hazırla butonuna basmayınız.</p>

    <Col className="d-flex justify-content-end mb-3">
      <Button variant="secondary" onClick={handleClose} style={{marginRight:'10px'}}>
        Vazgeç
      </Button>
      <Button variant="primary" onClick={handleExport}>
        Hazırla
      </Button>
    </Col>
  </Row>
  {isPreparing && (!myDataResult || !myDataResult.error) && 
  
  <div>
  <div>Ürün listeniz hazırlanıyor, lütfen bekleyin...</div>

  <img src={gif} style={{width:'50%'}} alt="export gif" />
  </div>
  
  }
  {myDataResult && myDataResult[0]?.Value?.length > 0  && 
  <Row className="justify-content-center mt-5" style={{width:'100%'}}>
    <Col style={{display: "flex", justifyContent: "center"}}>
    <Alert variant="success" style={{marginRight:'10px', height:'40px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding:"10px", fontSize:"13px"}}>

        {/* {myDataS3?.message} */}
        Ürün listesiniz hazır indirebilirsiniz.
      </Alert>
      <Button 
  variant="primary" 
  style={{width:'150px', height:'40px'}}
  // onClick={() => window.location.href = myDataS3?.download_url}
  onClick={handleDownload}
>
  İndir
</Button>

    </Col>
  </Row>

  }
   </Row>
        </Modal.Footer>
      </Modal> 

      <Modal 
      show={uploadShow} 
      onHide={handleUploadClose}
      size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Ürün Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <div style={{display: "flex", justifyContent: "flex-end", marginRight: "8px"}}>
  { myDataVendor?.length > 1 &&    
    <Form.Select 
      className='form-control' 
      style={{fontSize:"15px", width:"145px"}} 
      onChange={handleChangeVendor}
    
    >
      <option value="">Satıcı</option>
      {myDataVendor?.map((item, i) => {
        return <option key={i} value={item.Id}>{item.vendor_name}</option>
      })}       
    </Form.Select>
  }
</div>

            <p>- İndir butonunu kullanarak örnek excel dosyasını indiriniz.</p>
            <p>- Excel dosyasında belirtilen sütunları değiştirmeyiniz.</p>
            <p>- Title, SKU ve Barcode sütunu zorunludur.</p>
            <p>- Image sütununa ürün resimi için URL girilmelidir(max. 300 karakter).</p>
            <p>- Ağırlık "gram", Hacim "desi" birimi olarak girilmelidir..</p>
            {myDataVendor?.length > 1 ?  <p>- Satıcı seçimi zorunludur.</p> : ""}


        </Modal.Body>

        <Modal.Footer>
            <Form style={{width:"100%"}}>
              <Form.Group style={{ display: "flex", justifyContent: "space-between", width:"100%"}}>
                <div>
                  <Form.Control type="file" ref={fileInput} style={{border: 'none'}} />
                </div>
                <div>

                <Button variant="primary" onClick={handleProductExport}>
                    Örnek excel indir
                  </Button>
                  <Button style={{marginLeft:"5px"}} variant="secondary" onClick={handleClose}>
                    Vazgeç
                  </Button>
                 
                </div>
              </Form.Group>

              <div style={{ display: "flex", justifyContent: "center", marginTop:"60px" }}>
                <Button variant="primary" onClick={handleUploadClick}>Yükle</Button>
              </div>

              {fileName && <p style={{ textAlign: "center" }}>Selected file: {fileName}</p>}
            </Form>
                  {/* {errorMessage && <Alert variant="danger">{errorMessage}</Alert>} */}
            </Modal.Footer>

      </Modal>






    </AdvanceTableWrapper>
  );
};

export default ProductList;
