import React, { useContext, useEffect, useState } from 'react';
import { Card, Dropdown, Form, InputGroup, Row, Col, Alert } from 'react-bootstrap';
import { orderList } from 'data/ecommerce/orderList';
import CardDropdown from 'components/common/CardDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
// import OrdersTableHeader from './OrdersTableHeader';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import useProductListAll from 'hooks/useProductListAll';
import IconButton from 'components/common/IconButton';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import useProductExport from 'hooks/useProductExport';
import Accordion from 'react-bootstrap/Accordion';
import { Search } from 'react-bootstrap-icons';
import { useProductUpload } from 'hooks/useProductUpload';
import { useRef } from 'react';
import useProductExampleExport from 'hooks/useProductExampleExport';
import useProductExportS3 from 'hooks/useProductExportS3';
import ProductTableHeader from './ProductTableHeader';
import PaginationProduct from './PaginationProduct';
import { useNavigate } from "react-router-dom";
import { PermsContext } from 'context/PermsContext';
import useVendorList from 'hooks/useVendorList';
import useBarcodeSku from 'hooks/useBarcodeSku';
import gif from 'assets/img/gif_export_s3.gif'
import { MdCheck, MdOutlineModeEdit, MdOutlineCancel} from "react-icons/md";
import useProductCriticalStockUpdate from 'hooks/useProductCriticalStockUpdate';


const ProductListInventory = () => {

  const columns = [
    {
      // accessor: 'name',
      Header: 'Ürün',
      disableSortBy: true,
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { image, barcode, sku, id } = rowData.row.original;
        const state= { barcode, sku, id }

        return (
          <>
    
          <Link 
          to={`/e-commerce/product/product-inventory-details?barcode=${state.barcode}&sku=${state.sku}&id=${state.id}`}
          state={state}
          >
          
          <img style={{width:"40px", maxWidth:"100%",  transition:"transform 0.5s"}} 
            src={image} alt=""
            onMouseOver={(e) => e.target.style.transform="scale(1.2)"}
            onMouseOut={(e) => e.target.style.transform="scale(1)"}
            />
            </Link>
          </>
        );
      }
    },
  
    {
      accessor: 'name',
      disableSortBy: true,
      Header: <span style={{marginLeft:"15px"}}>İsim</span> ,
      Cell: rowData => {
        const { title, barcode, sku,id } = rowData.row.original;
        const state= { barcode, sku, id }
        return (
          <>
  
      <Link 
        to={`/e-commerce/product/product-inventory-details?barcode=${state.barcode}&sku=${state.sku}&id=${state.id}`}
        state={state}>
             <td style={{ color:"#637386"}}
                    title={title}
                    onMouseOver={(e) => (e.currentTarget.title = title)}
                    >
                {title.length > 60
                        ? title.slice(0, 60).concat("...")
                        : title}
                  </td>
                  </Link>
          </>
        );
      }
    },
    {
      accessor: 'status',
      disableSortBy: true,
      Header: 'Barkodu',
      headerProps: {
        className: 'text-left'
      },
      cellProps: {
        className: 'text-left fs-0'
      },
      Cell: rowData => {
        const { barcode } = rowData.row.original;
        return (
          <>
         <p style={{ fontSize: "15px" }} >{ barcode }</p>
        
          </>
        )
      }
    },
    {
      accessor: 'sku',
      Header: 'SKU',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center fs-0'
      },
      Cell: rowData => {
        const { sku } = rowData.row.original;
        return (
          <>
      
         <p style={{ fontSize: "15px" }} >{ sku }</p>
          </>
        )
      }
    },
    { 
      accessor: 'critical_stock',
      Header: 'Kritik Stok',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Cell: ({ row }) => {
        const [editMode, setEditMode] = useState(false);
        const [editedValue, setEditedValue] = useState(row.original.critical_stock);

        

        const handleEditClick = () => {
          setEditMode(true);
        };

        const handleCheckClick = () => {
          console.log('Yeni değer kaydediliyor:', editedValue);
          
          setFormData({
            ...formData, "product_id": row.original.id,
                          "CriticalStock": editedValue,
                        
                  })  
        setHandleUpdate(true)
        setEditMode(false);
        
        };
        const handleCancelClick = () => {
          console.log('Yeni değer kaydediliyor:', editedValue);
          setEditMode(false);
        };

        return editMode ? (
          <>
            <input 
              type="text" 
              value={editedValue} 
              onChange={e => setEditedValue(e.target.value)} 
              style={{ width: "20%", marginRight:'5px', border: '1px solid #5E6E82'}}
            />
            <MdCheck onClick={handleCheckClick} style={{ cursor: 'pointer', fontSize:'20px', marginRight:'5px' }}/>

            <MdOutlineCancel onClick={handleCancelClick} style={{ cursor: 'pointer', fontSize:'20px' }}/>
          </>
        ) : (
          <>
            <span style={{marginRight:'8px'}}>{row.original.critical_stock}</span>
            {hasPermission('Dashboard Katalog Ürün Düzenleme') && <MdOutlineModeEdit onClick={handleEditClick} style={{ cursor: 'pointer', fontSize:'18px', marginBottom:'7px'}}/>}
          </>
        );
      }
    },
    {
      accessor: 'commited',
      Header: 'Revize Stok',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center fs-0'
      },
      Cell: rowData => {
        const { committed_stock } = rowData.row.original;
        return (
          <>
      
         <p style={{ fontSize: "15px" }} >{ committed_stock }</p>
          </>
        )
      }
    },
    {
      accessor: 'avaible',
      Header: 'Satılabilir Stok',
      disableSortBy: true,
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center fs-0'
      },
      Cell: rowData => {
        const { comitted } = rowData.row.original;
        return (
          <>
      
         <p style={{ fontSize: "15px" }} >{ comitted }</p>
          </>
        )
      }
    },
    {
      accessor: 'stock',
      Header: () => (
        <div 
          style={{display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer'}} 
          className='text-center' 
          onClick={onHeaderClick}
        >
         Stok &nbsp; 
            {
              sortState === 1 && <span style={{display: 'block', fontSize:"9px", color:"#B5C2D1"}}>&#9660;</span> 
            }
            {
              sortState === 2 && <span style={{display: 'block', fontSize:"9px", color:"#B5C2D1"}}>&#9650;</span> 
            }
            {
              sortState === 3 && (
                <div>
                  <span style={{display: 'block', marginBottom:"-5px", fontSize:"8px", color:"#B5C2D1"}}>&#9650;</span> 
                  <span style={{display: 'block', fontSize:"8px", color:"#B5C2D1"}}>&#9660;</span> 
                </div>
              )
    }
        </div>
      ),
      disableSortBy: true,
      // Header: 'Stok',
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center fs-0'
      },
      Cell: rowData => `${rowData.row.original.stock}`
    }
  ];

const {myData, setPage, setSearch, setStock, stock, currentPage,setCurrentPage, followersPerPage, setFollowersPerPage, totalPages,sortState, setSortState, setVendorId, setSubProduct, getData, setCritical} = useProductListAll()
const {myDataS3,setMyDataS3, getDataExport, showGif, setShowGif, myDataResult, isPreparing, setIsPreparing, setMyDataResult, setVendorUsername, setVendorIdExport} = useProductExportS3()


const { postData, setFormData, formData} = useProductCriticalStockUpdate()

const { vendorIdContext} = useContext(PermsContext)



const {myDataVendor} = useVendorList()
const {perms} = useContext(PermsContext)

  function hasPermission(permission) {
    return perms?.includes(permission);
  }

const onHeaderClick = () => {
  setSortState((sortState % 3) + 1);
};

const handleChangeVendorModal = (e)=> {
  // setVendorUsername(e.target.value); 
  setVendorIdExport(e.target.value)
}


useEffect(() => {
  if (myDataVendor && myDataVendor.length > 0) {
    const firstVendorId = myDataVendor[0].Id;
    
    setVendorId(firstVendorId)
    setVendorIdExport(firstVendorId)
   
  }
}, [myDataVendor]);



const handleChangeVendor = (e)=> {
  
  setVendorId(e.target.value)
 
}




function hasPermission(permission) {
  return perms?.includes(permission);
}

const [handleUpdate, setHandleUpdate] = useState(false)

  
useEffect(() => {
  setSubProduct('false')
  getData()
  setHandleUpdate(false)

}, [handleUpdate])


const [activePage, setActivePage] = useState(1)

useEffect(() => {

  setPage(activePage)

}, [activePage])


const [isOpen, setIsOpen] = useState(false);

// Handler
const handleToggle = () => {
  setIsOpen(!isOpen);
};


const { uploadFile, errorMessage  } = useProductUpload()
const fileInput = useRef(null);
const [fileName, setFileName] = useState('');


const handleUploadClick = () => {
  if (fileInput.current.files.length > 0) {
      setFileName(fileInput.current.files[0].name);
      uploadFile(fileInput.current.files[0]);
      setUploadShow(false)
    
  } else {
      console.log("No file chosen!");
  }
  setFileName("")
};

const {getDataProductExport} = useProductExampleExport()

const handleProductExport = () => {
  getDataProductExport()

}


// modal

const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const [uploadShow, setUploadShow] = useState(false)

const handleUploadClose = () => setUploadShow(false);
const handleUploadShow = () => setUploadShow(true);




const handleExport = ()=> { 
  getDataExport(); 
  setShowGif(true);
  // setShow(false)

  

  setIsPreparing(true);
}

const handleDownload = () => {
  setIsPreparing(false);
  window.location.href = myDataResult[0]?.Value;
  setShow(false)
  setMyDataResult(false)
}




const orderList = myData?.results
  .map(item => {
   
    return {
      id: item.Id,
      title: item.Title,
      barcode: item.barcodes__Barcode,
      sku: item.SKU,
      stock: Number(item.InventoryCount) || 0,
      image: item.Image,
      critical_stock : item.CriticalStock || 0,
      comitted: item.InventoryCount - item.CommittedCount,
      reserve: item.CommittedCount,
      vendor_id : vendorIdContext,
      committed_stock : item.CommittedCount || 0,

      
    };
  });






const selectStock = [
  {value: "zero_greater_than", label:"Sıfır olmayanlar"},
  {value:99, label:"< 100"},
  {value:101, label:"100-500"},
  {value:501, label:"+ 500"},

]


const selectStockCritical = [
  {value: true, label:"Evet"},
  {value: false, label:"Hayır"} 

]



const handleChange = (e)=> {
  setStock(e.target.value); 
  console.log("stock", stock)
}

const handleChangeCritical = (e)=> {
  setCritical(e.target.value); 
}



  return (
    <AdvanceTableWrapper
      columns={columns}
      data={orderList || []} 
      selection
      sortable
      pagination
      perPage={50}
    >
      <Card className="mb-3">
        <Card.Header>
        <div style={{display:"flex", justifyContent:"space-between"}}>
        <div>
            <ProductTableHeader table />
        </div>
        <div style={{display:"flex", justifyContent:'right'}}>
            <div className="mb-3" style={{marginRight:"8px"}}>
                  <InputGroup>
                    <Form.Control  type="text" placeholder="barkod, sku, isim..."  onChange={(e)=>setSearch(e.target.value)}/>
                    <InputGroup.Text><Search/></InputGroup.Text>
              </InputGroup>
                  </div>

                  {hasPermission('Stok Excel Export') && (
                <div style={{marginRight:"8px"}}>
                  <IconButton
                        variant="falcon-default"
                        size="m"
                        icon="external-link-alt"
                        transform="shrink-3"
                        onClick={handleShow}
                      >
                        <span className="d-none d-sm-inline-block ms-1">Export</span>
                      </IconButton>
                      </div>
                     )} 
                      {/* <div style={{marginRight:"8px"}}>
                <IconButton
                  variant="falcon-default"
                  size="m"
                  icon="plus"
                  transform="shrink-3"
                  onClick={handleUploadShow}
                  style={{width:'195px', fontSize:'15px'}}
                >
                  <span className="d-none d-sm-inline-block ms-1">Ürün Ekle</span>
                </IconButton>
                </div> */}
        <Dropdown  show={isOpen} onToggle={handleToggle}>
          <Dropdown.Toggle
              bsPrefix="toggle"
              as={Link}
              //  to=""
              className={classNames('px-0 nav-link')}>
            <IconButton
              variant="falcon-default"
              size="m"
              icon="fa-sharp fa-light fa-filter-list"
              transform="shrink-3"
              style={{width:'145px', fontSize:'15px'}}>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-filter-right" viewBox="0 0 16 16">
                <path d="M14 10.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-7a.5.5 0 0 0 0 1h7a.5.5 0 0 0 .5-.5zm0-3a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0 0 1h11a.5.5 0 0 0 .5-.5z"/>
              </svg>
                  <span className="d-none d-sm-inline-block ms-1">Filtre</span>
            </IconButton>
        </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret" style={{minWidth: '300px', width: '300px'}}>

          <Card className="shadow-none shadow-show-xl scrollbar">
            <Card.Header className="bg-light d-none d-xl-block">
              <h6 className="mb-0">Filtre</h6>
            </Card.Header>
              <Card.Body>
                <Form>

                { hasPermission('Dashboard Çoklu Satıcı Görme') ?  
                  <div style={{marginBottom:'10px'}}>
                  <Form.Label className="mb-1 mt-2 fs--1" style={{color:"#5E6E82"}}>Satıcı</Form.Label>
                    { myDataVendor?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeVendor}>
                     <option value="" disabled>Satıcı</option>
                        { myDataVendor?.map((item, i)=>{
                              return <option key={i} value={item.Id}>{item.vendor_name}</option>
                        })}       
                </Form.Select>}
            </div>
            : "" }
                  <div className="mb-2">
                  <Form.Label className="mb-1 mt-2 fs--1" style={{color:"#5E6E82"}}>Stok</Form.Label>
                  <Form.Select  className='form-control' style={{fontSize:"17px"}} onChange={handleChange}>
                     <option value=""  >Stok</option>
                        {selectStock?.map((item, i)=>{
                              return <option  key={i} value={item.value}>{item.label}</option>
                        })}
                </Form.Select> 
                  </div>

                  <div className="mb-2">
                  <Form.Label className="mb-1 mt-2 fs--1" style={{color:"#5E6E82"}}>Kritik Stok Altında Mı?</Form.Label>
                  <Form.Select  className='form-control' style={{fontSize:"17px"}} onChange={handleChangeCritical}>
                     <option value=""  >Hepsi</option>
                        {selectStockCritical?.map((item, i)=>{
                              return <option  key={i} value={item.value}>{item.label}</option>
                        })}
                </Form.Select> 
                  </div>
                 
              </Form>
            </Card.Body>
        </Card>
      </Dropdown.Menu>
      </Dropdown>
      </div>
    </div>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
           <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
            {showGif && <img src={gif} alt="loading gif" />}
          </div>
        </Card.Body>
        <Card.Footer>
          {/* <AdvanceTablePagination table /> */}
          <PaginationProduct pages={totalPages} activePage={activePage} setActivePage={setActivePage} setCurrentPage={setCurrentPage}/>
        </Card.Footer>
      </Card>
      <span style={{marginRight:"5px",marginLeft:"5px"}}>Ürün sayısı</span>
      <Button variant={followersPerPage === 10 ? 'primary' : ''} onClick={() => setFollowersPerPage(10)}>10</Button>
      <Button variant={followersPerPage === 25 ? 'primary' : ''} onClick={() => setFollowersPerPage(25)}>25</Button>
      <Button variant={followersPerPage === 50 ? 'primary' : ''} onClick={() => setFollowersPerPage(50)}>50</Button>
      <span style={{marginLeft:"5px"}}> </span>

     <Modal 
      show={show} 
      onHide={handleClose}
  
      >
        <Modal.Header closeButton>
          <Modal.Title>Envanterim Listesi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
        <p>Lütfen Satıcı seçimi yaparak ürün listesini indiriniz.</p>
        <div style={{marginRight:'8px'}}>
                    { myDataVendor?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px", width:"145px"}} onChange={handleChangeVendorModal}>
                     <option value="">Satıcı</option>
                        { myDataVendor?.map((item, i)=>{
                              return <option key={i} value={item.Id}>{item.vendor_name}</option>
                        })}       
                </Form.Select>}
            </div>


          </Modal.Body>
        <Modal.Footer>
        <Row style={{width:'100%'}}>      
  <Row>

  
    <p>Envanter Listeniz hazırlanıyor yazısından sonra tekrar Hazırla butonuna basmayınız.</p>

    <Col className="d-flex justify-content-end mb-3">
      <Button variant="secondary" onClick={handleClose} style={{marginRight:'10px'}}>
        Vazgeç
      </Button>
      <Button variant="primary" onClick={handleExport}>
        Hazırla
      </Button>
    </Col>
  </Row>
  {isPreparing && (!myDataResult || !myDataResult.error) && 
  
  <div>
  <div>Envanter listeniz hazırlanıyor, lütfen bekleyin...</div>

  <img src={gif} style={{width:'50%'}} alt="export gif" />
  </div>
  
  }
  {myDataResult && myDataResult[0]?.Value?.length > 0  && 
  <Row className="justify-content-center mt-5" style={{width:'100%'}}>
    <Col style={{display: "flex", justifyContent: "center"}}>
    <Alert variant="success" style={{marginRight:'10px', height:'40px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding:"10px", fontSize:"13px"}}>

        {/* {myDataS3?.message} */}
        Envanter listesiniz hazır indirebilirsiniz.
      </Alert>
      <Button 
  variant="primary" 
  style={{width:'150px', height:'40px'}}
  // onClick={() => window.location.href = myDataS3?.download_url}
  onClick={handleDownload}
>
  İndir
</Button>

    </Col>
  </Row>

  }
   </Row>
        </Modal.Footer>
      </Modal> 

      <Modal 
      show={uploadShow} 
      onHide={handleUploadClose}
      size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Ürün Ekle</Modal.Title>
        </Modal.Header>
        <Modal.Body>

            <p>- İndir butonunu kullanarak örnek excel dosyasını indiriniz.</p>
            <p>- Excel dosyasında belirtilen sütunları değiştirmeyiniz.</p>
            <p>- Title, SKU ve Barcode sütunu zorunludur.</p>
            <p>- Image sütununa ürün resimi için URL girilmelidir(max. 300 karakter).</p>
            <p>- Ağırlık "gram", Hacim "desi" birimi olarak girilmelidir..</p>


        </Modal.Body>

        <Modal.Footer>
            <Form style={{width:"100%"}}>
              <Form.Group style={{ display: "flex", justifyContent: "space-between", width:"100%"}}>
                <div>
                  <Form.Control type="file" ref={fileInput} style={{border: 'none'}} />
                </div>
                <div>

                <Button variant="primary" onClick={handleProductExport}>
                    Örnek excel indir
                  </Button>
                  <Button style={{marginLeft:"5px"}} variant="secondary" onClick={handleClose}>
                    Vazgeç
                  </Button>
                 
                </div>
              </Form.Group>

              <div style={{ display: "flex", justifyContent: "center", marginTop:"60px" }}>
                <Button variant="primary" onClick={handleUploadClick}>Yükle</Button>
              </div>

              {fileName && <p style={{ textAlign: "center" }}>Selected file: {fileName}</p>}
            </Form>
                  {/* {errorMessage && <Alert variant="danger">{errorMessage}</Alert>} */}
            </Modal.Footer>

      </Modal>






    </AdvanceTableWrapper>
  );
};

export default ProductListInventory;