import { AuthContext } from 'context/AuthContext';
import React, { useState, useEffect, useContext } from 'react'
import {BASE_URL} from './baseUrl'
import useForecastData from './useForecastData';


export default function useProductForecast() {



 const [myData, setMyData] = useState()

 const {mySelectData} = useForecastData()
 const {myKey, currentUser} = useContext(AuthContext)
 const [selectBarcode, setSelectBarcode] = useState()
 const [selectStore, setSelectStore] = useState()
 const [selectVendorId, setSelectVendorId] = useState()




 useEffect(() => {
      if (mySelectData && mySelectData.length > 0) {
           setSelectBarcode(mySelectData[0].Product__barcodes__Barcode);
          //  setSelectStore(mySelectData[0].VendorStore__Store__Name)
          // setVendorStore(mySelectData[0].VendorStore__Store__Name)
    // tüm tahinleri alabilmek için setSelectStore yoruma aldık
}
 }, [mySelectData])
 


 const getData = ()=>{
        var myHeaders = new Headers();


        const params = {
          barcode :selectBarcode,
          vendor_id : selectVendorId,
          vendor_store : selectStore
         
        };
      
        const request_url = Object.entries(params)
            .filter(([_, value]) => Boolean(value)) // truthy değerleri filtrele
            .reduce(
              (url, [key, value]) => url + `&${key}=${value}`,
              `${BASE_URL}api/v1/order/sales_total_daily_forecast/?`
      );


        
        
        var requestOptions = {
          method: 'GET',
          headers: {"Authorization": "Token "+myKey},
          redirect: 'follow'
        };
        fetch(request_url, requestOptions)
          .then(response => response.json())
          .then(result =>{
            setMyData(result) 
            
    })
          .catch(error => console.log('error', error));        
 }




 useEffect(() => {
    getData();

  }, [selectBarcode, selectStore, selectVendorId]);

 return {myData,selectBarcode, setSelectBarcode, setSelectStore, setSelectVendorId }
}
