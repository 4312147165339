import useIntegrationCreate from 'hooks/useIntegrationCreate';
import React, { useState } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { BsArrowLeft } from "react-icons/bs";
import { Link } from 'react-router-dom';

const IntegrationForm = () => {
  const location = useLocation();
  const { name, item } = location.state || {}; 
  const { postData } = useIntegrationCreate();

  const [formValues, setFormValues] = useState(() => {
    const initialValues = {};
    [...item.required_keys, ...item.optional_keys.filter(key => !key.readonly), ...item.private_required_keys, ...item.private_optional_keys.filter(key => !key.readonly)].forEach(keyObj => {
      initialValues[keyObj.name] = '';
    });
    item.radio_keys.filter(key => !key.readonly).forEach(keyObj => {
      initialValues[keyObj.name] = false; 
    });
    return initialValues;
  });

  const handleInputChange = (key, value) => {
    setFormValues(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      name: name,
      variables: Object.entries(formValues).filter(([_, value]) => value !== '').map(([key, value]) => ({
        key,
        value,
      }))
    };

    postData(formData);
    resetFormValues();
  };

  const resetFormValues = () => {
    const resetValues = {};
    [...item.required_keys, ...item.optional_keys.filter(key => !key.readonly), ...item.private_required_keys, ...item.private_optional_keys.filter(key => !key.readonly)].forEach(keyObj => {
      resetValues[keyObj.name] = ''; 
    });
    item.radio_keys.filter(key => !key.readonly).forEach(keyObj => {
      resetValues[keyObj.name] = false; 
    });
    setFormValues(resetValues);
  };

  const isRequired = (key) => {
    return [...item.required_keys, ...item.private_required_keys].some(keyObj => keyObj.name === key);
  };

  const renderFormElement = (keyObj) => {
    const { name } = keyObj;
    const isSwitch = item.radio_keys.some(radioKey => radioKey.name === name);

    return isSwitch ? (
      <Form.Group className="mb-3" controlId={`formSwitch${name}`} key={`switch-${name}`}>
        <Form.Check 
          type="switch" 
          label={name} 
          checked={!!formValues[name]}
          onChange={(e) => handleInputChange(name, e.target.checked)}
        />
      </Form.Group>
    ) : (
      <Form.Group className="mb-3" controlId={`form${name}`} key={name}>
        <Form.Label>{`${name}${isRequired(name) ? ' *' : ''}`}</Form.Label>
        <Form.Control
          type="text"
          placeholder={name}
          value={formValues[name] || ''}
          onChange={(e) => handleInputChange(name, e.target.value)}
          required={isRequired(name)}
        />
      </Form.Group>
    );
  };

  return (
    <>
      <Link to="/e-commerce/integration">
        <span style={{color:"#5E6E82", fontWeight:"500", marginBottom:"5px", fontSize:"13px"}}>
          <BsArrowLeft /> &nbsp; Geri Dön
        </span>
      </Link>
      <Card className="mb-3 mt-1" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <div className="mb-3 mt-3" style={{width:"95%"}}>
          <h6>{name} Entegrasyonu Oluşturma</h6>
          <hr />
        </div>
        <Form.Group style={{ border: "0px solid gray", padding:"10px", borderRadius:"10px", width:"80%" }}>
          <Form onSubmit={handleSubmit}>
            {[...item.required_keys, ...item.optional_keys.filter(key => !key.readonly), ...item.private_required_keys, ...item.private_optional_keys.filter(key => !key.readonly), ...item.radio_keys.filter(key => !key.readonly)].map(renderFormElement)}
            <Button variant="primary" type="submit" className="d-flex mx-auto mt-5 mb-2">
              Onayla
            </Button>
          </Form>
        </Form.Group>
      </Card>
    </>
  );
};

export default IntegrationForm;
