import React, { useContext } from 'react';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import IntegrationCard from './IntegrationCard';
import useVendorStoreList from 'hooks/useVendorStoreList';
import useVendorList from 'hooks/useVendorList';
import { PermsContext } from 'context/PermsContext';

const IntegrationAvailable = () => {

  const {myDataStore, setVendorId}= useVendorStoreList()
  const {myDataVendor} = useVendorList()
  
const {perms} = useContext(PermsContext)

  function hasPermission(permission) {
    return perms?.includes(permission);
  }



  
  const handleChangeVendor = (e)=> {
    setVendorId(e.target.value); 
    
    
  }

  return (
  <>
      <Col sm={12} lg={12} style={{marginBottom:'30px'}}>
          <Card 
          style={{ backgroundColor: "#fff", color:'#5E6E82'}}
          >
            <Card.Body>
            <Card.Text style={{fontSize:'20px'}}>
              ShipShack ile e-ticaret çok kolay!
              </Card.Text>
            </Card.Body>
            </Card>
      </Col>
      <div style={{display:"flex", justifyContent:"space-between", marginTop:'15px', marginLeft:'15px', marginBottom:'30px'}}>
          { hasPermission('Dashboard Çoklu Satıcı Görme') ?  
                            <div style={{marginRight:"10px"}}>
                              { myDataVendor?.length> 0 &&    < Form.Select  className='form-control' style={{fontSize:"15px"}} onChange={handleChangeVendor}>
                              <option value="" disabled>Satıcı</option>
                                  { myDataVendor?.map((item, i)=>{
                                        return <option key={i} value={item.Id}>{item.vendor_name}</option>
                                  })}       
                </Form.Select>}
            </div>
            : "" }
      </div>
 
      <Row>
        {myDataStore
          ?.filter(item => item.Store?.Name !== "Custom" && item.Store?.Name !== "Custom-B2B") 
          .map((item) => (
            <IntegrationCard item={item} key={item.Id} id={item.Id} name={item.Store.Name} image={item.Store.Image} uuid={item.UUID} abv={item.Vendor.Abbreviation}  />
          ))}
      </Row>
  
    </>
  );
};

export default IntegrationAvailable;